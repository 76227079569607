/**
 * @file        // src/pages/ProfilePage.js
 * @version     3.1.0
 * @author      
 * @date        2025-01-24
 * @lastModified 2025-01-24
 * 
 * @summary
 * Renders the user's profile page within the WalletCollector app. This page allows users to view and manage
 * their Managed Lists, upgrade to premium, set a unique username, and handle account-related actions such as
 * deleting their account or managing subscriptions. It also includes features like leaderboard display,
 * List filtering, and multi-criteria sorting to enhance user experience.
 * 
 * @description
 * The `ProfilePage` component is a React functional component that provides a comprehensive interface for users
 * to interact with their profile data. It leverages Firebase for data management and authentication,
 * Material-UI (MUI) for styling and UI components, and React Router for navigation. The component manages
 * multiple states to handle Lists, subscriptions, user details, and UI dialogs. It also includes
 * various effects to fetch and listen to real-time updates from Firestore, ensuring that the UI remains
 * responsive and up-to-date with the latest data.
 * 
 * Key Features:
 * - **List Management:** Users can view, filter, sort, and manage their Lists.
 * - **Premium Subscription:** Allows users to upgrade to premium, manage subscriptions, and access premium features.
 * - **Username Setting:** Premium users can set a unique username.
 * - **Leaderboard:** Displays top followers based on List participation.
 * - **Account Management:** Users can delete their accounts with appropriate confirmations.
* - **Responsive Design:** Utilizes MUI's theming and responsive utilities to ensure a seamless experience across devices.

 * @license
 * © 2025 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 * 
 * @changelog
 * - **3.1.0**: Added brand filtering and multi-criteria sorting for raffles.
 * - **3.0.0**: Integrated premium subscription management and username setting.
 * - **2.5.0**: Implemented real-time listeners for user and subscription data.
 * - **2.0.0**: Enhanced documentation for better scalability and maintainability.
 * - **1.0.0**: Initial creation of the Profile Page component.
 */


import React, { useEffect, useState, useMemo } from 'react';
import { firestore, auth } from '../firebase';
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  deleteDoc,
  doc,
  onSnapshot,
  runTransaction,
} from 'firebase/firestore';
import { deleteUser } from 'firebase/auth';
import { Link } from 'react-router-dom';

// MUI imports
import {
  Typography,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Paper,
  Button,
  useTheme,
  useMediaQuery,
  Stack,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { grey } from '@mui/material/colors';

// Custom Components
import HandleUpgradeToPremiumButton from '../components/HandleUpgradeToPremiumButton';
import SharedRaffles from '../components/SharedRaffles';
import SharePermissionsDialog from '../components/SharePermissionsDialog';

// Verified icon
import verifiedIcon from '../assets/images/verifiedbutton.png';

/**
 * @component
 * @param {object} props - Component properties.
 * @param {object} props.user - The authenticated user object.
 * @returns {JSX.Element} The rendered Profile Page component.
 */
const ProfilePage = ({ user }) => {
  const [raffles, setRaffles] = useState([]);
  const [allEntries, setAllEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [monthlyRaffleCount, setMonthlyRaffleCount] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // Premium / Subscription
  const [isPremium, setIsPremium] = useState(false);
  const [subscriptionCreated, setSubscriptionCreated] = useState(null);

  // Next reset date
  const [nextResetDate, setNextResetDate] = useState(null);

  // Leaderboard
  const [selectedRaffleType, setSelectedRaffleType] = useState('Hide Leaderboard');
  const [leaderboardEntries, setLeaderboardEntries] = useState([]);
  const [leaderboardLoading, setLeaderboardLoading] = useState(false);

  // Verified pop-up
  const [openVerifiedDialog, setOpenVerifiedDialog] = useState(false);

  // Username
  const [username, setUsername] = useState('');
  const [usernameLoading, setUsernameLoading] = useState(false);
  const [usernameError, setUsernameError] = useState('');
  const [usernameSuccess, setUsernameSuccess] = useState('');

  // Detailed user doc
  const [detailedUser, setDetailedUser] = useState(null);

  // Share Permissions
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [currentRaffleId, setCurrentRaffleId] = useState(null);

  // Filter for “Your Lists”
  const [blockchainFilter, setBlockchainFilter] = useState('All');

  // **New State for Brand Filtering**
  const [brandFilter, setBrandFilter] = useState('All Brands');

  // Multi-criteria Sort
  const [openClosedSort, setOpenClosedSort] = useState(''); // 'openFirst' | 'closedFirst' | ''
  const [entryCountSort, setEntryCountSort] = useState(''); // 'asc' | 'desc' | ''
  const [nameSort, setNameSort] = useState(''); // 'asc' | 'desc' | ''

  // Brands Mapping
  const [brandsMap, setBrandsMap] = useState({});
  const [brandsLoading, setBrandsLoading] = useState(true);


  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // ----------------------------------
  // Effects: Load data
  // ----------------------------------
  useEffect(() => {
    if (!user) return;

    const fetchData = async () => {
      try {
        // 1) Fetch all raffles for user
        const rafflesRef = collection(firestore, 'raffles');
        const qRaffles = query(rafflesRef, where('ownerId', '==', user.uid));
        const rafflesSnapshot = await getDocs(qRaffles);

        // 2) Count how many created this month
        const currentMonthStart = new Date();
        currentMonthStart.setDate(1);
        currentMonthStart.setHours(0, 0, 0, 0);

        let earliestRaffleDateThisMonth = null;
        const monthly = rafflesSnapshot.docs.filter((docSnap) => {
          const data = docSnap.data();
          const cAt = data.createdAt?.toDate();
          if (cAt && cAt >= currentMonthStart) {
            if (!earliestRaffleDateThisMonth || cAt < earliestRaffleDateThisMonth) {
              earliestRaffleDateThisMonth = cAt;
            }
            return true;
          }
          return false;
        });
        setMonthlyRaffleCount(monthly.length);

        // 3) Next reset date
        if (earliestRaffleDateThisMonth) {
          const resetDate = new Date(earliestRaffleDateThisMonth);
          resetDate.setMonth(resetDate.getMonth() + 1);
          setNextResetDate(resetDate);
        } else {
          const resetDate = new Date();
          resetDate.setDate(1);
          resetDate.setMonth(resetDate.getMonth() + 1);
          resetDate.setHours(0, 0, 0, 0);
          setNextResetDate(resetDate);
        }

        // 4) Build full raffles array (exclude “deleted”)
        const fetchedRaffles = await Promise.all(
          rafflesSnapshot.docs
            .filter((d) => !d.data().deleted)
            .map(async (docSnap) => {
              const r = { id: docSnap.id, ...docSnap.data() };
              const entrantsQ = query(
                collection(firestore, 'raffleEntries'),
                where('raffleId', '==', docSnap.id)
              );
              const entrantsSnapshot = await getDocs(entrantsQ);
              r.entrantCount = entrantsSnapshot.size || 0;
              return r;
            })
        );
        setRaffles(fetchedRaffles);

        // 5) Combine all entries for leaderboard
        const allRaffleIds = fetchedRaffles.map((r) => r.id);
        const batchSize = 10;
        const batches = [];
        for (let i = 0; i < allRaffleIds.length; i += batchSize) {
          batches.push(allRaffleIds.slice(i, i + batchSize));
        }

        let combinedEntries = [];
        for (const batch of batches) {
          const entriesQ = query(
            collection(firestore, 'raffleEntries'),
            where('raffleId', 'in', batch)
          );
          const entriesSnapshot = await getDocs(entriesQ);
          entriesSnapshot.forEach((d) => {
            combinedEntries.push(d.data());
          });
        }
        setAllEntries(combinedEntries);

        // 6) Fetch user's brands if premium
        if (isPremium) {
          const brandsRef = collection(firestore, 'users', user.uid, 'brands');
          const qBrands = query(brandsRef);
          const brandsSnapshot = await getDocs(qBrands);
          const brandsData = {};
          brandsSnapshot.forEach((docSnap) => {
            brandsData[docSnap.id] = docSnap.data().name; // Assuming each brand has a 'name' field
          });
          setBrandsMap(brandsData);
          setBrandsLoading(false);
        } else {
          setBrandsMap({});
          setBrandsLoading(false);
        }

      } catch (err) {
        console.error('Error fetching data:', err);
      
        setBrandsLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // Listen for subscription => isPremium
    const subsRef = collection(firestore, 'users', user.uid, 'subscriptions');
    const qSubs = query(subsRef, where('status', '==', 'active'));
    const unsubscribeSubs = onSnapshot(qSubs, (querySnapshot) => {
      const hasActiveSub = !querySnapshot.empty;
      setIsPremium(hasActiveSub);

      if (hasActiveSub) {
        const docSnap = querySnapshot.docs[0];
        if (docSnap) {
          const data = docSnap.data();
          if (data.created) {
            setSubscriptionCreated(data.created);
          } else {
            setSubscriptionCreated(null);
          }
        }
      } else {
        setSubscriptionCreated(null);
      }
    });

    // Listen for user doc => username, etc.
    const userDocRef = doc(firestore, 'users', user.uid);
    const unsubscribeUserDoc = onSnapshot(userDocRef, (docSnap) => {
      if (docSnap.exists()) {
        setDetailedUser(docSnap.data());
      } else {
        setDetailedUser(null);
      }
    });

    return () => {
      unsubscribeSubs();
      unsubscribeUserDoc();
    };
  }, [user, isPremium]);

  // Leaderboard effect
  useEffect(() => {
    if (!raffles.length || !allEntries.length || selectedRaffleType === 'Hide Leaderboard') {
      setLeaderboardEntries([]);
      return;
    }
    setLeaderboardLoading(true);

    const lowerType = selectedRaffleType.toLowerCase();
    const relevantRaffles = raffles.filter(
      (r) => r.raffleType && r.raffleType.toLowerCase() === lowerType
    );
    if (relevantRaffles.length === 0) {
      setLeaderboardEntries([]);
      setLeaderboardLoading(false);
      return;
    }

    const rIds = relevantRaffles.map((r) => r.id);
    const relevantEntries = allEntries.filter((e) => rIds.includes(e.raffleId));

    // Aggregate by address
    const entryMap = new Map();
    relevantEntries.forEach((entry) => {
      const addrKey = entry.address.toLowerCase();
      if (!entryMap.has(addrKey)) {
        entryMap.set(addrKey, {
          address: entry.address,
          twitterHandle: entry.twitterHandle || '',
          raffleIds: new Set(),
        });
      }
      const data = entryMap.get(addrKey);
      data.raffleIds.add(entry.raffleId);

      if (!data.twitterHandle && entry.twitterHandle) {
        data.twitterHandle = entry.twitterHandle;
      }
    });

    let lbData = Array.from(entryMap.values()).map((e) => ({
      address: e.address,
      twitterHandle: e.twitterHandle,
      distinctRaffles: e.raffleIds.size,
    }));

    lbData.sort((a, b) => b.distinctRaffles - a.distinctRaffles);
    lbData = lbData.slice(0, 5);

    setLeaderboardEntries(lbData);
    setLeaderboardLoading(false);
  }, [raffles, allEntries, selectedRaffleType]);

  // ----------------------------------
  // Handlers
  // ----------------------------------
  /**
   * Handles the deletion of the user's account. Ensures that the user has canceled their premium subscription
   * before proceeding. Deletes all raffles owned by the user and then deletes the user from Firebase Authentication.
   */
  const handleDeleteAccount = async () => {
    if (isPremium) {
      alert('Please cancel your subscription before deleting your account.');
      return;
    }
    try {
      const rafflesRef = collection(firestore, 'raffles');
      const q = query(rafflesRef, where('ownerId', '==', user.uid));
      const qsnap = await getDocs(q);
      const promises = qsnap.docs.map((docSnap) =>
        deleteDoc(doc(firestore, 'raffles', docSnap.id))
      );
      await Promise.all(promises);

      await deleteUser(auth.currentUser);
      alert('Account deleted successfully.');
    } catch (error) {
      console.error('Error deleting account:', error);
      alert('Failed to delete the account. Please try again.');
    }
  };

  const openDeleteDialogHandler = () => setOpenDeleteDialog(true);
  const closeDeleteDialogHandler = () => setOpenDeleteDialog(false);

  /**
   * Formats the subscription creation date into a readable string.
   * @returns {string} The formatted subscription date.
   */
  const formatSubDate = () => {
    if (!subscriptionCreated) return '';
    const dateObj = subscriptionCreated.toDate();
    const month = dateObj.toLocaleString('default', { month: 'long' });
    const year = dateObj.getFullYear();
    return `${month} ${year}`;
  };

  // Share Permissions
  const handleOpenShareDialog = (raffleId) => {
    setCurrentRaffleId(raffleId);
    setOpenShareDialog(true);
  };
  const handleCloseShareDialog = () => {
    setCurrentRaffleId(null);
    setOpenShareDialog(false);
  };

  /**
   * Handles the deletion of a specific raffle by marking it as deleted in Firestore.
   * @param {string} raffleId - The ID of the raffle to be deleted.
   */
  const handleDeleteRaffle = async (raffleId) => {
    const confirmDel = window.confirm(
      'Are you sure you want to delete this raffle? This action cannot be undone.'
    );
    if (!confirmDel) return;

    try {
      const raffleRef = doc(firestore, 'raffles', raffleId);
      await setDoc(raffleRef, { deleted: true }, { merge: true });
      setRaffles((prev) => prev.filter((r) => r.id !== raffleId));
      alert('Raffle deleted successfully.');
    } catch (error) {
      console.error('Error deleting raffle:', error);
      alert('Failed to delete. Please try again.');
    }
  };

  /**
   * Handles the submission of a new username. Validates the input and ensures uniqueness before setting it.
   * @param {Event} e - The form submission event.
   */
  const handleSetUsername = async (e) => {
    e.preventDefault();
    setUsernameError('');
    setUsernameSuccess('');

    if (!username) {
      setUsernameError('Username cannot be empty.');
      return;
    }
    const usernameRegex = /^[a-zA-Z0-9_]{3,15}$/;
    if (!usernameRegex.test(username)) {
      setUsernameError('3-15 chars, letters, numbers, underscores only.');
      return;
    }

    setUsernameLoading(true);
    const usernameDocRef = doc(firestore, 'usernames', username.toLowerCase());
    const userDocRef = doc(firestore, 'users', user.uid);

    try {
      await runTransaction(firestore, async (transaction) => {
        const usernameDoc = await transaction.get(usernameDocRef);
        if (usernameDoc.exists()) {
          throw new Error('Username is already taken.');
        }

        const userDoc = await transaction.get(userDocRef);
        if (userDoc.exists() && userDoc.data().username) {
          throw new Error('You already have a username. Contact support to change it.');
        }

        transaction.set(usernameDocRef, {
          username: username.toLowerCase(),
          uid: user.uid,
        });
        transaction.update(userDocRef, {
          username: username.toLowerCase(),
        });
      });

      setUsernameSuccess('Username set successfully!');
      setUsername('');
    } catch (err) {
      console.error('Error setting username:', err);
      setUsernameError(err.message || 'Failed to set username.');
    } finally {
      setUsernameLoading(false);
    }
  };

  // ----------------------------------
  //  Filter & Multi-Level Sort
  // ----------------------------------
  /**
   * Filters raffles based on selected blockchain and brand filters.
   * @returns {Array} The filtered raffles.
   */
  const filteredRaffles = useMemo(() => {
    let result = raffles;

    // 1) Filter by Blockchain
    if (blockchainFilter !== 'All') {
      result = result.filter(
        (r) => r.raffleType && r.raffleType.toLowerCase() === blockchainFilter.toLowerCase()
      );
    }

    // 2) Filter by Brand
    if (isPremium && brandFilter !== 'All Brands') {
      result = result.filter((r) => r.selectedBrand === brandFilter);
    }

    return result;
  }, [raffles, blockchainFilter, brandFilter, isPremium]);

  /**
   * Sorts the filtered raffles based on open/closed status, entry count, and name.
   * @returns {Array} The sorted raffles.
   */
  const sortedRaffles = useMemo(() => {
    // 2) Sort: multi-level approach
    const out = [...filteredRaffles];

    // Precompute open/closed property
    out.forEach((r) => {
      r.isOpen = new Date(r.entryDeadline) > new Date();
    });

    out.sort((a, b) => {
      // -----------------------
      // 1) Open/Closed sort
      // -----------------------
      if (openClosedSort === 'openFirst') {
        // We want open first => sort "desc" on `isOpen`
        const aVal = a.isOpen ? 1 : 0;
        const bVal = b.isOpen ? 1 : 0;
        if (bVal - aVal !== 0) return bVal - aVal;
      } else if (openClosedSort === 'closedFirst') {
        // We want closed first => sort "asc" on `isOpen`
        const aVal = a.isOpen ? 1 : 0;
        const bVal = b.isOpen ? 1 : 0;
        if (aVal - bVal !== 0) return aVal - bVal;
      }

      // -----------------------
      // 2) Entry Count sort
      // -----------------------
      if (entryCountSort === 'asc') {
        if (a.entrantCount !== b.entrantCount) {
          return a.entrantCount - b.entrantCount;
        }
      } else if (entryCountSort === 'desc') {
        if (b.entrantCount !== a.entrantCount) {
          return b.entrantCount - a.entrantCount;
        }
      }

      // -----------------------
      // 3) Name sort
      // -----------------------
      const aName = a.name || '';
      const bName = b.name || '';
      if (nameSort === 'asc') {
        const cmp = aName.localeCompare(bName);
        if (cmp !== 0) return cmp;
      } else if (nameSort === 'desc') {
        const cmp = bName.localeCompare(aName);
        if (cmp !== 0) return cmp;
      }

      // If all sorting criteria yield "equal," then 0
      return 0;
    });

    return out;
  }, [filteredRaffles, openClosedSort, entryCountSort, nameSort]);

  /**
   * Calculates the maximum number of entrants across all raffles to normalize the horizontal bar widths.
   * @returns {number} The maximum entrant count.
   */
  const maxEntrants = useMemo(() => {
    if (!sortedRaffles.length) return 1; // prevent divide-by-zero
    return Math.max(...sortedRaffles.map((r) => r.entrantCount), 1);
  }, [sortedRaffles]);

  // ----------------------------------
  // Render
  // ----------------------------------
  if (!user) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography>Please sign in to view your profile.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      {/* Profile / Verified header */}
      <Typography variant="h5" gutterBottom>
        {isPremium && detailedUser?.username ? (
          <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', fontWeight: 'bold' }}>
            {detailedUser.username}
            <Box
              component="img"
              src={verifiedIcon}
              alt="Verified"
              onClick={() => setOpenVerifiedDialog(true)}
              sx={{
                display: 'inline-block',
                height: '2.2em',
                width: 'auto',
                ml: 1,
                verticalAlign: 'middle',
                cursor: 'pointer',
              }}
            />
          </Box>
        ) : (
          <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
            Your Profile
            {isPremium && (
              <Box
                component="img"
                src={verifiedIcon}
                alt="Verified"
                onClick={() => setOpenVerifiedDialog(true)}
                sx={{
                  display: 'inline-block',
                  height: '1.5em',
                  width: 'auto',
                  ml: 1,
                  verticalAlign: 'middle',
                  cursor: 'pointer',
                }}
              />
            )}
          </Box>
        )}
      </Typography>

      {/* Verified dialog */}
      <Dialog open={openVerifiedDialog} onClose={() => setOpenVerifiedDialog(false)}>
        <DialogTitle>Account Verification</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {detailedUser?.username ? (
              subscriptionCreated ? (
                `User ${detailedUser.username} has been Premium Verified since ${formatSubDate()}.`
              ) : (
                `User ${detailedUser.username} is Premium, but no creation date found.`
              )
            ) : (
              'Your account is verified, but no username was set.'
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenVerifiedDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Basic Profile info */}
      <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6}>
          <Typography>Name: {user.displayName || 'N/A'}</Typography>
          <Typography>Email: {user.email}</Typography>
          <Typography>Account Status: {isPremium ? 'Premium' : 'Free'}</Typography>

          <Box display="flex" alignItems="center" mt={1}>
            <Tooltip
              title={
                nextResetDate
                  ? isPremium
                    ? 'Unlimited Lists per month.'
                    : `Resets on ${nextResetDate.toLocaleDateString()} at ${nextResetDate.toLocaleTimeString()}.`
                  : 'No reset info available.'
              }
            >
              <Typography variant="body1" sx={{ cursor: 'pointer' }}>
                Monthly Raffles Created: {monthlyRaffleCount}
                {isPremium ? ' / Unlimited' : ' / 5'}
              </Typography>
            </Tooltip>
            <Tooltip
              title={
                nextResetDate
                  ? isPremium
                    ? 'Enjoy unlimited creation!'
                    : `You can create up to 5 Lists until ${nextResetDate.toLocaleDateString()}.`
                  : 'No info available.'
              }
            >
              <IconButton size="small" sx={{ ml: 1 }}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>

        {!isPremium && (
          <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
            <HandleUpgradeToPremiumButton user={user} />
          </Grid>
        )}
      </Grid>

      {/* Username creation (premium only, if none set) */}
      {isPremium && !detailedUser?.username && (
        <Box
          sx={{
            p: 2,
            border: '1px solid #ccc',
            borderRadius: 2,
            backgroundColor: '#fafafa',
            mb: 3,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Set Your Unique Username
          </Typography>
          <form onSubmit={handleSetUsername}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={8}>
                <TextField
                  label="Username"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  disabled={usernameLoading}
                  helperText="3-15 chars, letters/digits/underscores only."
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={usernameLoading}
                  size="small"
                >
                  {usernameLoading ? <CircularProgress size={20} /> : 'Set Username'}
                </Button>
              </Grid>
            </Grid>
          </form>
          {usernameError && (
            <Typography color="error" sx={{ mt: 1 }}>
              {usernameError}
            </Typography>
          )}
          {usernameSuccess && (
            <Typography color="success.main" sx={{ mt: 1 }}>
              {usernameSuccess}
            </Typography>
          )}
        </Box>
      )}

      {/* Leaderboard */}
      <Box sx={{ mb: 3 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'flex-start', sm: 'center' },
            justifyContent: 'space-between',
            mb: 2,
          }}
        >
          <FormControl 
            size="small"
            sx={{ width: 250 }} // <-- Use fixed width rather than just minWidth
          >
            <InputLabel 
              id="leaderboard-select-label"
              sx={{ whiteSpace: 'nowrap' }} // Prevent label text wrapping
            >
              My Top Followers
            </InputLabel>
            <Select
              labelId="leaderboard-select-label"
              label="My Top Followers"
              value={selectedRaffleType}
              onChange={(e) => setSelectedRaffleType(e.target.value)}
              fullWidth // Make Select stretch to full FormControl width
              MenuProps={{
                PaperProps: {
                  style: {
                    minWidth: 250, // Ensure the dropdown itself is also at least 250px
                  },
                },
              }}
            >
              <MenuItem value="Hide Leaderboard">Hide Leaderboard</MenuItem>
              <MenuItem value="Ethereum">Ethereum</MenuItem>
              <MenuItem value="Solana">Solana</MenuItem>
              <MenuItem value="Bitcoin">Bitcoin</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {selectedRaffleType !== 'Hide Leaderboard' && (
          <Paper sx={{ p: 2 }}>
            {leaderboardLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
                <CircularProgress />
              </Box>
            ) : leaderboardEntries.length === 0 ? (
              <Typography>No entrants for {selectedRaffleType} yet.</Typography>
            ) : (
              <Box sx={{ overflowX: 'auto' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr style={{ backgroundColor: '#f5f5f5' }}>
                      <th style={{ textAlign: 'left', padding: '8px' }}>Rank</th>
                      <th style={{ textAlign: 'left', padding: '8px' }}>Address</th>
                      <th style={{ textAlign: 'left', padding: '8px' }}>Twitter Handle</th>
                      <th style={{ textAlign: 'right', padding: '8px' }}>
                        Total Distinct Lists Entered
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {leaderboardEntries.map((entry, i) => {
                      const rank = i + 1;
                      const handle = entry.twitterHandle || '';
                      const link = handle.startsWith('@')
                        ? `https://twitter.com/${handle.slice(1)}`
                        : handle
                        ? `https://twitter.com/${handle}`
                        : '';

                      return (
                        <tr key={i} style={{ borderBottom: '1px solid #ddd' }}>
                          <td style={{ padding: '8px' }}>{rank}</td>
                          <td
                            style={{
                              padding: '8px',
                              maxWidth: isSmallScreen ? '100px' : '200px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                            title={entry.address}
                          >
                            {entry.address}
                          </td>
                          <td style={{ padding: '8px' }}>
                            {handle ? (
                              <a
                                href={link}
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: '#1DA1F2', textDecoration: 'none' }}
                              >
                                {handle}
                              </a>
                            ) : (
                              <span style={{ color: grey[500] }}>N/A</span>
                            )}
                          </td>
                          <td style={{ padding: '8px', textAlign: 'right' }}>
                            {entry.distinctRaffles}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Box>
            )}
          </Paper>
        )}
      </Box>

      {/* Share Permissions Dialog (premium) */}
      {isPremium && currentRaffleId && (
        <SharePermissionsDialog
          open={openShareDialog}
          onClose={handleCloseShareDialog}
          ownerId={user.uid}
          raffleId={currentRaffleId}
        />
      )}

     {/* YOUR LISTS - Now uses multi-level sort */}
  <Typography variant="h5" gutterBottom>
    Your Lists
  </Typography>

{/* Filter & MULTI-level Sort Controls */}
<Grid container spacing={1} alignItems="center" sx={{ mb: 2 }}>
  {/* Consolidated Filters */}
  <Grid item xs={12}>
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={2}
      sx={{ width: '100%' }}
    >
      {/* Filter by Blockchain */}
      <FormControl size="small" sx={{ flex: 1, minWidth: 150 }}>
        <InputLabel id="blockchain-filter-label">Filter by Blockchain</InputLabel>
        <Select
          labelId="blockchain-filter-label"
          value={blockchainFilter}
          label="Filter by Blockchain"
          onChange={(e) => setBlockchainFilter(e.target.value)}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Ethereum">Ethereum</MenuItem>
          <MenuItem value="Solana">Solana</MenuItem>
          <MenuItem value="Bitcoin">Bitcoin</MenuItem>
        </Select>
      </FormControl>

      {/* Brand Filter Dropdown */}
      {isPremium && !brandsLoading && (
        <FormControl size="small" sx={{ flex: 1, minWidth: 150 }}>
          <InputLabel id="brand-filter-label">Filter by Brand</InputLabel>
          <Select
            labelId="brand-filter-label"
            value={brandFilter}
            label="Filter by Brand"
            onChange={(e) => setBrandFilter(e.target.value)}
          >
            <MenuItem value="All Brands">All Brands</MenuItem>
            {Object.entries(brandsMap).map(([brandId, brandName]) => (
              <MenuItem key={brandId} value={brandId}>
                {brandName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Stack>
  </Grid>

  {/* MULTI-CRITERIA SORTING */}
  <Grid item xs={12}>
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
      {/* Open/Closed Sort */}
      <FormControl size="small" sx={{ minWidth: 150 }}>
        <InputLabel
          id="open-closed-sort-label"
          shrink
          sx={{
            whiteSpace: 'normal',       // Allow text to wrap
            lineHeight: 1.2,            // Adjust line height for readability
            wordBreak: 'break-word',    // Break words if necessary
          }}
        >
          Open/Closed Sort
        </InputLabel>
        <Select
          displayEmpty                  // Add this line
          labelId="open-closed-sort-label"
          value={openClosedSort}
          label="Open/Closed Sort"
          onChange={(e) => setOpenClosedSort(e.target.value)}
        >
          <MenuItem value="">Ignore Open/Closed</MenuItem>
          <MenuItem value="openFirst">Open First</MenuItem>
          <MenuItem value="closedFirst">Closed First</MenuItem>
        </Select>
      </FormControl>

      {/* Entry Count Sort */}
      <FormControl size="small" sx={{ minWidth: 159 }}>
        <InputLabel
          id="entry-count-sort-label"
          shrink
          sx={{
            whiteSpace: 'normal',       // Allow text to wrap
            lineHeight: 1.2,            // Adjust line height for readability
            wordBreak: 'break-word',    // Break words if necessary
          }}
        >
          Entry Count Sort
        </InputLabel>
        <Select
          displayEmpty                  // Add this line
          labelId="entry-count-sort-label"
          value={entryCountSort}
          label="Entry Count Sort"
          onChange={(e) => setEntryCountSort(e.target.value)}
        >
          <MenuItem value="">Ignore Entry Count</MenuItem>
          <MenuItem value="asc">Low → High</MenuItem>
          <MenuItem value="desc">High → Low</MenuItem>
        </Select>
      </FormControl>

      {/* Name Sort */}
      <FormControl size="small" sx={{ minWidth: 150 }}>
        <InputLabel
          id="name-sort-label"
          shrink
          sx={{
            whiteSpace: 'normal',       // Allow text to wrap
            lineHeight: 1.2,            // Adjust line height for readability
            wordBreak: 'break-word',    // Break words if necessary
          }}
        >
          Name Sort
        </InputLabel>
        <Select
          displayEmpty                  // Add this line
          labelId="name-sort-label"
          value={nameSort}
          label="Name Sort"
          onChange={(e) => setNameSort(e.target.value)}
        >
          <MenuItem value="">Ignore Name</MenuItem>
          <MenuItem value="asc">A → Z</MenuItem>
          <MenuItem value="desc">Z → A</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  </Grid>
</Grid>


  {/* Lists Display */}
  {loading || (isPremium && brandsLoading) ? (
    <Typography>Loading Lists...</Typography>
  ) : sortedRaffles.length === 0 ? (
    <Typography>No Lists to display.</Typography>
  ) : (
    // Instead of a single Paper, map each raffle to its own Paper
    <Box>
      {sortedRaffles.map((raffle) => {
        const { id, name, raffleType, entrantCount, isOpen, selectedBrand } = raffle;
        const brandName = selectedBrand ? brandsMap[selectedBrand] : null;

        return (
          <Paper key={id} elevation={3} sx={{ p: 2, mb: 2 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: 2,
              }}
            >
              {/* Raffle Name, Type & Brand */}
              <Box sx={{ minWidth: '130px' }}>
                <Typography variant="subtitle1" noWrap>
                  {name || 'Untitled'}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {raffleType || 'N/A'}
                </Typography>
                {brandName && (
                  <Typography variant="caption" color="primary" sx={{ mt: 0.5, display: 'block' }}>
                    Brand: {brandName}
                  </Typography>
                )}
              </Box>

              {/* Open/Closed Label */}
              <Box
                sx={{
                  minWidth: '60px',
                  textAlign: 'center',
                  color: isOpen ? 'green' : 'red',
                  fontWeight: 'bold',
                }}
              >
                {isOpen ? 'Open' : 'Closed'}
              </Box>

              {/* Horizontal Bar (entry count) */}
              <Box sx={{ flexGrow: 1, minWidth: '150px' }}>
                <Tooltip title={`Entrants: ${entrantCount}`} arrow>
                  <Box
                    sx={{
                      position: 'relative',
                      height: 24,
                      backgroundColor: '#eee',
                      borderRadius: 1,
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: `${(entrantCount / maxEntrants) * 100}%`,
                        bgcolor: isOpen ? 'black' : 'black',
                        transition: 'width 0.4s ease',
                      }}
                    />
                  </Box>
                </Tooltip>
              </Box>

              {/* Action Buttons */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  flexWrap: 'wrap',
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  component={Link}
                  to={`/raffle/${id}`}
                >
                  View
                </Button>
                {isPremium && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={() => handleOpenShareDialog(id)}
                  >
                    Manage
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => handleDeleteRaffle(id)}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </Paper>
        );
      })}
    </Box>
  )}

      {/* If Premium => Shared Raffles */}
      {isPremium && <SharedRaffles user={user} />}

      {/* Delete or Manage Subscription */}
      {isPremium ? (
        <Box sx={{ mt: 4, mb: 2 }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() =>
              (window.location.href = 'https://billing.stripe.com/p/login/eVa2aP2y26r53Qs3cc')
            }
          >
            Manage Subscription
          </Button>
        </Box>
      ) : (
        <>
          <Box sx={{ mt: 4, mb: 2 }}>
            <Button variant="outlined" color="error" onClick={openDeleteDialogHandler}>
              Delete My Account
            </Button>
          </Box>

          <Dialog open={openDeleteDialog} onClose={closeDeleteDialogHandler}>
            <DialogTitle>Delete Account</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete your account?
                This action cannot be undone, and all your lists
                will be permanently deleted.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDeleteDialogHandler}>Cancel</Button>
              <Button
                onClick={() => {
                  closeDeleteDialogHandler();
                  handleDeleteAccount();
                }}
                color="error"
              >
                Confirm Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default ProfilePage;
