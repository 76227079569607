/**
 * @file   HomePage.js
 * @brief  Serves as the landing page for the WalletCollector app, providing a welcoming message and
 *         actions based on the user's authentication and subscription status. Displays "Create Wallet
 *         Collector" if the user is signed in, and an "Upgrade to Premium" button if the user is not a premium member.
 * @date   2025-01-24
 *
 * © 2025 Bissonette Data Solutions Corp. All Rights Reserved.
 *
 * LICENSE:
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 */

import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography, Box, Grid } from '@mui/material';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth, firestore } from '../firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';

// Lazy load the HandleUpgradeToPremiumButton component
const HandleUpgradeToPremiumButton = lazy(() => import('../components/HandleUpgradeToPremiumButton'));

const boxStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: { xs: '1rem', md: '2rem' },
};

const gridContainerStyles = {
  flexGrow: 1,
  // Removed negative margins
  paddingTop: { xs: '0px', sm: '0px', md: '0px', lg: '0px' },
};

const typographyStyles = {
  fontSize: { xs: '2rem', md: '3rem' },
  marginBottom: { xs: '1rem', md: '1.5rem' },
};

const HomePage = ({ user }) => {
  const [isPremium, setIsPremium] = useState(false);

  useEffect(() => {
    if (!user) {
      setIsPremium(false);
      return;
    }

    const subscriptionsRef = collection(firestore, 'users', user.uid, 'subscriptions');
    const qSubscriptions = query(subscriptionsRef, where('status', '==', 'active'));

    const unsubscribe = onSnapshot(
      qSubscriptions,
      (querySnapshot) => {
        setIsPremium(!querySnapshot.empty);
      },
      (error) => {
        console.error('Error listening to subscription documents:', error);
        setIsPremium(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [user]);

  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error during sign-in:', error);
      alert('Failed to sign in. Please try again.');
    }
  };

  return (
    <Box sx={boxStyles}>
      <Grid
        container
        spacing={4}
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={gridContainerStyles}
      >
        <Grid item xs={8}>
          <Typography variant="h4" align="center" gutterBottom sx={typographyStyles}>
            Welcome to WalletCollector
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          {user ? (
            <Box textAlign="center">
              <Typography variant="body1" gutterBottom>
                Effortlessly collect your followers' Bitcoin, Ethereum, or Solana wallets
                into manageable lists for your social media giveaways!
              </Typography>

              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/create"
                sx={{ mt: 2, px: 4, py: 1 }}
              >
                Create Wallet Collector
              </Button>

              {!isPremium && (
                <Suspense fallback={<div>Loading...</div>}>
                  <Box sx={{ mt: 2 }}>
                    <HandleUpgradeToPremiumButton user={user} />
                  </Box>
                </Suspense>
              )}
            </Box>
          ) : (
            <Box textAlign="center">
              <Typography variant="body1" gutterBottom>
                Enter your crypto address into your desired list or sign in to create your own.
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  justifyContent: 'center',
                  gap: '1rem',
                  mt: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSignIn}
                  sx={{ px: 4, py: 1, width: { xs: '100%', sm: 'auto' } }}
                >
                  Sign In with Google
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSignIn}
                  sx={{ px: 4, py: 1, width: { xs: '100%', sm: 'auto' } }}
                >
                  Create Account with Google
                </Button>
              </Box>
              <Typography
                variant="caption"
                align="center"
                sx={{ mt: 2, fontSize: '0.65rem' }}
              >
                By using Wallet Collector, you confirm that you have read and agree to the{' '}
                <Link to="/terms" target="_blank" rel="noopener noreferrer">
                  Terms
                </Link>{' '}
                and{' '}
                <Link to="/policy" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </Link>.
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomePage;

