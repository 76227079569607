/**
 * @file        /src/components/SharedRaffles.js
 * @version     2.1.0
 * @author      Trevor Bissonette
 * @date        2024-04-27
 * @lastModified 2025-01-25
 * 
 * @summary
 * Displays a list of Lists shared with the authenticated user within the WalletCollector application.
 * Handles data fetching from Firestore, manages loading and error states, and provides navigation to List details.
 * 
 * @description
 * The `SharedLists` component allows users to view Lists that have been shared with them by other List creators.
 * It fetches shared List permissions from Firestore and retrieves the corresponding List details. The component handles
 * Firestore's query limitations by batching requests when there are more than ten shared List. It also filters out any
 * raffles that have been marked as deleted. The user interface is built using Material-UI components to ensure responsiveness
 * and a consistent look and feel across different devices.
 * 
 * Key Features:
 * - **Data Fetching:** Retrieves shared List permissions and corresponding List details from Firestore.
 * - **Batch Processing:** Handles Firestore's 'in' query limitations by batching List ID requests when necessary.
 * - **Error Handling:** Manages and displays errors that occur during data fetching.
 * - **Responsive UI:** Utilizes Material-UI components to provide a user-friendly and responsive interface.
 * - **Navigation:** Enables users to navigate to detailed views of each shared List.
 * - **Scalability:** Designed to efficiently handle a large number of shared Lists without performance degradation.
 * 
 * @license
 * © 2025 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 * 
 * @changelog
 * - **2.1.0**: Added inline documentation for improved readability and maintainability. Enhanced batch processing for Firestore queries to support more shared raffles.
 * - **2.0.0**: Refactored component for better performance and scalability. Improved error handling and user feedback mechanisms.
 * - **1.0.0**: Initial creation of the SharedRaffles component with basic data fetching and display functionality.
 */

import React, { useEffect, useState } from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  CircularProgress,
  Box,
  Paper,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { firestore } from '../firebase';
import {
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';

/**
 * SharedRaffles Component
 * 
 * @param {object} user - The authenticated user object.
 */
const SharedRaffles = ({ user }) => {
  // State to hold the list of shared raffles
  const [sharedRaffles, setSharedRaffles] = useState([]);
  
  // State to manage loading status
  const [loading, setLoading] = useState(true);
  
  // State to capture any errors during data fetching
  const [error, setError] = useState('');

  /**
   * useEffect hook to fetch shared raffles when the component mounts or when the user changes.
   */
  useEffect(() => {
    // If no user is authenticated, do not proceed with fetching
    if (!user) return;

    /**
     * Fetches shared raffles from Firestore based on the current user's UID.
     * Handles batching for Firestore 'in' queries which have a maximum of 10 elements.
     */
    const fetchSharedRaffles = async () => {
      setLoading(true);
      setError('');
      try {
        // 1. Query sharedPermissions where sharedWithUid equals the current user's UID
        const sharedPermissionsRef = collection(firestore, 'sharedPermissions');
        const qShared = query(
          sharedPermissionsRef,
          where('sharedWithUid', '==', user.uid)
        );
        const sharedSnap = await getDocs(qShared);

        // Extract raffle IDs from the sharedPermissions documents
        const raffleIds = sharedSnap.docs.map(docSnap => docSnap.data().raffleId);

        // If no raffles are shared, update the state accordingly
        if (raffleIds.length === 0) {
          setSharedRaffles([]);
          setLoading(false);
          return;
        }

        // 2. Fetch raffles corresponding to the raffleIds in batches of 10
        const rafflesRef = collection(firestore, 'raffles');
        const fetchRafflesBatch = async (ids) => {
          const q = query(
            rafflesRef,
            where('__name__', 'in', ids)
          );
          const rafflesSnapshot = await getDocs(q);
          return rafflesSnapshot.docs.map(docSnap => ({
            id: docSnap.id,
            ...docSnap.data(),
          }));
        };

        let fetchedRaffles = [];

        // Process raffleIds in batches of 10 to comply with Firestore's 'in' query limit
        for (let i = 0; i < raffleIds.length; i += 10) {
          const batchIds = raffleIds.slice(i, i + 10);
          const batchRaffles = await fetchRafflesBatch(batchIds);
          fetchedRaffles = [...fetchedRaffles, ...batchRaffles];
        }

        // 3. Filter out raffles that have been marked as deleted
        fetchedRaffles = fetchedRaffles.filter(raffle => !raffle.deleted);

        // Update the state with the fetched raffles
        setSharedRaffles(fetchedRaffles);
      } catch (err) {
        console.error('Error fetching shared raffles:', err);
        setError('Failed to load shared raffles.');
      } finally {
        setLoading(false);
      }
    };

    // Initiate the fetch operation
    fetchSharedRaffles();
  }, [user]);

  // If no user is authenticated, do not render the component
  if (!user) {
    return null; // Alternatively, display a message prompting the user to sign in
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Lists Shared With Me
      </Typography>
      <Paper elevation={3} sx={{ p: 2 }}>
        {loading ? (
          // Display a loading spinner while fetching data
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          // Display an error message if data fetching fails
          <Typography color="error">{error}</Typography>
        ) : sharedRaffles.length === 0 ? (
          // Inform the user if no raffles have been shared
          <Typography variant="body2">
            No lists have been shared with you.
          </Typography>
        ) : (
          // Display the list of shared raffles
          <List>
            {sharedRaffles.map(raffle => {
              // Determine if the raffle is still open based on the entryDeadline
              const isOpen = new Date(raffle.entryDeadline) > new Date();
              return (
                <ListItem key={raffle.id} divider>
                  <ListItemText
                    primary={raffle.name}
                    secondary={
                      <>
                        Type: {raffle.raffleType} -{' '}
                        <Typography
                          component="span"
                          sx={{
                            color: isOpen ? 'green' : 'red',
                            fontWeight: 'bold',
                          }}
                        >
                          {isOpen ? 'Open' : 'Closed'}
                        </Typography>{' '}
                        - Entrants: {raffle.entrantCount}
                      </>
                    }
                  />
                  {/* Button to navigate to the detailed view of the raffle */}
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/raffle/${raffle.id}`}
                  >
                    View
                  </Button>
                </ListItem>
              );
            })}
          </List>
        )}
      </Paper>
    </Box>
  );
};

export default SharedRaffles;
