/**
 * @file   SharedBrands.js
 * @brief  Displays brands that have been shared WITH the authenticated user,
 *         with the same card layout and editing UI as MyBrands (if permission allows).
 * @date   2025-01-25
 */

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  IconButton,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Popover,
  Snackbar,
  Alert,
} from '@mui/material';
import { ChromePicker } from 'react-color';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import { firestore, storage } from '../firebase';
import BrandSharePermissionsDialog from '../components/BrandSharePermissionsDialog';

const SharedBrands = ({ user }) => {
  const [sharedBrands, setSharedBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // ====== For brand editing / form dialog ======
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    backgroundColor: '#ffffff',
    textColor: '#000000',
    logoFile: null,
    logoUrl: '',
  });
  const [formLoading, setFormLoading] = useState(false);
  const [logoPreviewUrl, setLogoPreviewUrl] = useState('');

  // ====== Color picker anchors ======
  const [anchorElBg, setAnchorElBg] = useState(null);
  const [anchorElText, setAnchorElText] = useState(null);

  // ====== Snackbar ======
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // ====== Preview Dialog ======
  const [previewBrand, setPreviewBrand] = useState(null);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  // ====== Share Permissions Dialog (optional) ======
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [selectedBrandForSharing, setSelectedBrandForSharing] = useState(null);

  // ----------------------------------------------------
  // Fetch all brand docs that have been shared with user
  // ----------------------------------------------------
  useEffect(() => {
    if (!user) return;

    const fetchSharedBrands = async () => {
      setLoading(true);
      setError('');

      try {
        // 1) Find all docs in "sharedBrandPermissions" where sharedWithUid == user.uid
        const sharedBrandPermsRef = collection(firestore, 'sharedBrandPermissions');
        const qShared = query(sharedBrandPermsRef, where('sharedWithUid', '==', user.uid));
        const sharedSnap = await getDocs(qShared);

        if (sharedSnap.empty) {
          setSharedBrands([]);
          setLoading(false);
          return;
        }

        // 2) For each permission doc, fetch the actual Brand doc from the owner's subcollection
        const fetchedBrands = [];
        for (const permDoc of sharedSnap.docs) {
          const permData = permDoc.data();
          const { brandOwnerUid, brandId, permissionLevel } = permData;

          const brandRef = doc(firestore, 'users', brandOwnerUid, 'brands', brandId);
          const brandSnap = await getDoc(brandRef);

          if (brandSnap.exists()) {
            const brandData = brandSnap.data();
            // Optionally skip if brandData.deleted or brand is "soft deleted"
            if (brandData?.deleted) continue;

            fetchedBrands.push({
              id: brandId,
              ownerUid: brandOwnerUid,
              permissionLevel: permissionLevel || 'view', // "view" or "edit"
              ...brandData,
            });
          }
        }

        setSharedBrands(fetchedBrands);
      } catch (err) {
        console.error('Error fetching shared brands:', err);
        setError('Failed to load shared brands.');
      } finally {
        setLoading(false);
      }
    };

    fetchSharedBrands();
  }, [user]);

  // --------------------------------
  //   1) Form Dialog (Edit Brand)
  // --------------------------------
  const handleOpenForm = (brand) => {
    if (brand) {
      // Editing an existing shared brand
      setSelectedBrand(brand);
      setFormData({
        name: brand.name || '',
        description: brand.description || '',
        backgroundColor: brand.backgroundColor || '#ffffff',
        textColor: brand.textColor || '#000000',
        logoFile: null,
        logoUrl: brand.logoUrl || '',
      });
      setLogoPreviewUrl('');
    }
    setOpenFormDialog(true);
  };

  const handleCloseForm = () => {
    setOpenFormDialog(false);
    setSelectedBrand(null);
    setFormData({
      name: '',
      description: '',
      backgroundColor: '#ffffff',
      textColor: '#000000',
      logoFile: null,
      logoUrl: '',
    });
    setLogoPreviewUrl('');
    setAnchorElBg(null);
    setAnchorElText(null);
    setFormLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFormData((prev) => ({ ...prev, logoFile: file }));
      setLogoPreviewUrl(URL.createObjectURL(file));
    }
  };

  // --------------------------------
  //   2) Color Pickers
  // --------------------------------
  const handleOpenColorPickerBg = (event) => {
    setAnchorElBg(event.currentTarget);
  };
  const handleCloseColorPickerBg = () => {
    setAnchorElBg(null);
  };
  const handleBackgroundColorChange = (color) => {
    setFormData((prev) => ({ ...prev, backgroundColor: color.hex }));
  };

  const handleOpenColorPickerText = (event) => {
    setAnchorElText(event.currentTarget);
  };
  const handleCloseColorPickerText = () => {
    setAnchorElText(null);
  };
  const handleTextColorChange = (color) => {
    setFormData((prev) => ({ ...prev, textColor: color.hex }));
  };

  // --------------------------------
  //   3) Update Brand (if "edit")
  // --------------------------------
  const handleFormSubmit = async () => {
    if (!selectedBrand) {
      setSnackbar({
        open: true,
        message: 'No brand selected for editing.',
        severity: 'error',
      });
      return;
    }

    const { name, description, backgroundColor, textColor, logoFile } = formData;
    if (!name.trim()) {
      setSnackbar({
        open: true,
        message: 'Brand name is required.',
        severity: 'warning',
      });
      return;
    }

    setFormLoading(true);

    try {
      // 1) Upload new logo if changed
      let logoUrl = formData.logoUrl;
      if (logoFile) {
        const logoRef = ref(
          storage,
          `users/${selectedBrand.ownerUid}/brands/${Date.now()}_${logoFile.name}`
        );
        const snapshot = await uploadBytes(logoRef, logoFile);
        logoUrl = await getDownloadURL(snapshot.ref);
      }

      // 2) Update the brand doc in the brandOwnerUid path
      const brandDocRef = doc(
        firestore,
        'users',
        selectedBrand.ownerUid,
        'brands',
        selectedBrand.id
      );

      await updateDoc(brandDocRef, {
        name,
        description,
        backgroundColor,
        textColor,
        logoUrl,
        updatedAt: serverTimestamp(),
      });

      // 3) Reflect the change in our sharedBrands state
      setSharedBrands((prev) =>
        prev.map((b) =>
          b.id === selectedBrand.id
            ? { ...b, name, description, backgroundColor, textColor, logoUrl }
            : b
        )
      );

      setSnackbar({
        open: true,
        message: 'Brand updated successfully.',
        severity: 'success',
      });
      handleCloseForm();
    } catch (error) {
      console.error('Error updating shared brand:', error);
      setSnackbar({
        open: true,
        message: 'Failed to update brand. Please try again.',
        severity: 'error',
      });
    } finally {
      setFormLoading(false);
    }
  };

  // --------------------------------
  //   4) Preview Brand
  // --------------------------------
  const handleOpenPreview = (brand) => {
    setPreviewBrand(brand);
    setOpenPreviewDialog(true);
  };
  const handleClosePreview = () => {
    setPreviewBrand(null);
    setOpenPreviewDialog(false);
  };

  // --------------------------------
  //   5) Manage Share Permissions
  // --------------------------------
  const handleManagePermissions = (brand) => {
    // Typically you only allow the brand OWNER to manage sharing,
    // but you could allow "edit" users to re-share if desired.
    setSelectedBrandForSharing(brand);
    setShareDialogOpen(true);
  };
  const handleCloseShareDialog = () => {
    setShareDialogOpen(false);
    setSelectedBrandForSharing(null);
  };

  // --------------------------------
  //   6) Snackbar close
  // --------------------------------
  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  if (!user) {
    return null;
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Brands Shared With Me
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : sharedBrands.length === 0 ? (
        <Typography variant="body2">No brands have been shared with you.</Typography>
      ) : (
        <Grid container spacing={4}>
          {sharedBrands.map((brand) => (
            <Grid item key={brand.id} xs={12} sm={6} md={4}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                {/* Logo */}
                {brand.logoUrl && (
                  <CardMedia
                    component="img"
                    height="140"
                    image={brand.logoUrl}
                    alt={`${brand.name} Logo`}
                  />
                )}
                {/* Content */}
                <CardContent
                  sx={{
                    flexGrow: 1,
                    backgroundColor: brand.backgroundColor,
                    color: brand.textColor,
                  }}
                >
                  <Typography gutterBottom variant="h5" component="div">
                    {brand.name}
                  </Typography>
                  <Typography variant="body2" color="inherit">
                    {brand.description || 'No description provided.'}
                  </Typography>
                </CardContent>
                <CardActions>
                  {/* If permissionLevel === 'edit', show Edit button */} 
                  {brand.permissionLevel === 'edit' && (
                    <Tooltip title="Edit Brand" arrow>
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenForm(brand)}
                        aria-label="edit brand"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  )}

                  {/* Preview Brand */}
                  <Tooltip title="Preview Entry Page" arrow>
                    <IconButton
                      color="secondary"
                      onClick={() => handleOpenPreview(brand)}
                      aria-label="Preview Entry Page"
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>

                  {/* Possibly re-share if brand.ownerUid === user.uid or if your app allows it */}
                  {brand.ownerUid === user.uid && (
                    <Tooltip title="Share Brand Permissions" arrow>
                      <Button
                        variant="outlined"
                        onClick={() => handleManagePermissions(brand)}
                        sx={{ ml: 'auto' }}
                      >
                        Share
                      </Button>
                    </Tooltip>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Edit Form Dialog (permissionLevel === 'edit') */}
      <Dialog open={openFormDialog} onClose={handleCloseForm} fullWidth maxWidth="sm">
        <DialogTitle>Edit Shared Brand</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mt: 1,
            }}
          >
            <TextField
              required
              label="Brand Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              multiline
              rows={3}
              fullWidth
            />

            {/* Background color picker */}
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Background Color:
              </Typography>
              <Box
                onClick={handleOpenColorPickerBg}
                sx={{
                  width: '100%',
                  height: '40px',
                  backgroundColor: formData.backgroundColor,
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '10px',
                }}
              >
                <Typography variant="body2">{formData.backgroundColor}</Typography>
              </Box>
              <Popover
                open={Boolean(anchorElBg)}
                anchorEl={anchorElBg}
                onClose={handleCloseColorPickerBg}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              >
                <ChromePicker
                  color={formData.backgroundColor}
                  onChangeComplete={handleBackgroundColorChange}
                  disableAlpha
                />
              </Popover>
            </Box>

            {/* Text color picker */}
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Text Color:
              </Typography>
              <Box
                onClick={handleOpenColorPickerText}
                sx={{
                  width: '100%',
                  height: '40px',
                  backgroundColor: formData.textColor,
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '10px',
                }}
              >
                <Typography variant="body2">{formData.textColor}</Typography>
              </Box>
              <Popover
                open={Boolean(anchorElText)}
                anchorEl={anchorElText}
                onClose={handleCloseColorPickerText}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              >
                <ChromePicker
                  color={formData.textColor}
                  onChangeComplete={handleTextColorChange}
                  disableAlpha
                />
              </Popover>
            </Box>

            {/* Logo Upload */}
            <Button
              variant="contained"
              component="label"
              startIcon={<PhotoCamera />}
              sx={{ alignSelf: 'flex-start', mt: 2 }}
            >
              Change Logo
              <input type="file" hidden accept="image/*" onChange={handleLogoChange} />
            </Button>
            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
              Select a new logo to replace the existing one (optional).
            </Typography>

            {/* Local preview of newly selected file */}
            {logoPreviewUrl && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1">Selected Logo Preview:</Typography>
                <Box
                  component="img"
                  src={logoPreviewUrl}
                  alt="Local Logo Preview"
                  sx={{ width: '100%', maxHeight: '200px', objectFit: 'contain', mt: 1 }}
                />
              </Box>
            )}

            {/* Existing logo if no new file selected */}
            {!logoPreviewUrl && formData.logoUrl && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1">Current Logo:</Typography>
                <Box
                  component="img"
                  src={formData.logoUrl}
                  alt="Existing Brand Logo"
                  sx={{ width: '100%', maxHeight: '200px', objectFit: 'contain', mt: 1 }}
                />
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm} disabled={formLoading}>
            Cancel
          </Button>
          <Button onClick={handleFormSubmit} variant="contained" disabled={formLoading}>
            {formLoading ? <CircularProgress size={24} /> : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Preview Dialog (EXACT UI from MyBrands) */}
      <Dialog open={openPreviewDialog} onClose={handleClosePreview} fullWidth maxWidth="md">
        <DialogTitle>Preview Entry Page</DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          {previewBrand && (
            <Box
              sx={{
                backgroundColor: previewBrand.backgroundColor || '#ffffff',
                color: previewBrand.textColor || '#000000',
                minHeight: '600px',
                padding: '2rem',
              }}
            >
              {/* Same preview content as MyBrands */}
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontSize: { xs: '2rem', md: '2.5rem' },
                  textAlign: { xs: 'center', md: 'left' },
                  mb: 2,
                }}
              >
                Sample List Name
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{ textAlign: { xs: 'center', md: 'left' }, mb: 3 }}
              >
                This is a sample description for the list entry page. It provides details about the
                list, what participants can win, and any other relevant info.
              </Typography>

              <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" gutterBottom>
                    <strong>Type:</strong> Ethereum
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Entry Deadline:</strong> Jan 10, 2030, 04:20 AM
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" gutterBottom>
                    <strong>Max Entries:</strong> 1
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Total Entrants:</strong> 10,000
                  </Typography>
                </Grid>
              </Grid>

              {previewBrand.logoUrl && (
                <Box sx={{ textAlign: 'center', mb: 3 }}>
                  <img
                    src={previewBrand.logoUrl}
                    alt={`${previewBrand.name} Logo`}
                    style={{ maxHeight: '100px', borderRadius: '8px' }}
                  />
                </Box>
              )}

              <Box
                sx={{
                  backgroundColor: '#ffffff',
                  color: '#000000',
                  maxWidth: '500px',
                  margin: '0 auto',
                  padding: '1.5rem',
                  borderRadius: '8px',
                  boxShadow: 3,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Enter Your Address
                </Typography>
                <TextField
                  label="Your Address"
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 2 }}
                  disabled
                  placeholder="0x1234...abcd"
                />
                <TextField
                  label="Twitter Handle"
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 2 }}
                  disabled
                  placeholder="@username"
                />
                <Button variant="contained" color="primary" fullWidth disabled>
                  Submit
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePreview}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Share Permissions Dialog (if allowed) */}
      {selectedBrandForSharing && (
        <BrandSharePermissionsDialog
          open={shareDialogOpen}
          onClose={handleCloseShareDialog}
          brandOwnerUid={selectedBrandForSharing.ownerUid}
          brandId={selectedBrandForSharing.id}
          currentUserUid={user.uid}
        />
      )}

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SharedBrands;

