/**
 * @file        /src/components/RaffleCreationForm.js
 * @version     2.1.0
 * @author      Trevor Bissonette
 * @date        2024-04-27
 * @lastModified 2025-01-24
 * 
 * @summary
 * Provides a form component for creating Lists within the WalletCollector application. Handles form validation,
 * saves List data to Firestore, and generates shareable links for created Lists. Integrates premium features
 * such as brand inclusion for subscribed users.
 * 
 * @description
 * The `RaffleCreationForm` component facilitates the creation of Lists by authenticated users in the WalletCollector
 * application. It encompasses comprehensive form validation to ensure data integrity, interacts with Firebase Firestore
 * to store List details, and generates shareable links for users to distribute their Lists. Additionally, the component
 * incorporates premium functionalities, allowing premium users to include their brands in Lists, thereby enhancing the
 * user experience and offering advanced features to subscribed members.
 * 
 * Key Features:
 * - **Form Validation:** Implements robust validation mechanisms for all input fields, ensuring that users provide valid
 *   and complete information before submitting the form.
 * - **Firestore Integration:** Saves List details to Firebase Firestore, enabling persistent storage and real-time updates.
 * - **Shareable Links:** Generates unique, shareable links for each created List, allowing users to easily distribute their Lists.
 * - **Premium Features:** Supports premium functionalities such as including brands in Lists, accessible to users with active
 *   premium subscriptions.
 * - **Responsive Design:** Utilizes Material-UI (MUI) components to ensure the form is responsive and user-friendly across various devices.
 * - **Subscription Monitoring:** Listens to Firestore subscription documents to determine the user's premium status, dynamically adjusting
 *   available form options based on subscription.
 * - **User Feedback:** Provides real-time feedback and alerts to users during the List creation process, enhancing usability and engagement.
 * 
 * @license
 * © 2025 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 * 
 * @changelog
 * - **2.1.0**: Added premium feature support for brand inclusion and enhanced form validation.
 * - **1.0.0**: Initial creation of the RaffleCreationForm component with basic form handling and Firestore integration.
 */

import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase';
import {
  addDoc,
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  doc,
  getDoc,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import HandleUpgradeToPremiumButton from '../components/HandleUpgradeToPremiumButton';

import {
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  Alert,
  Tooltip,
  IconButton,
  Switch,
  FormGroup,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const RaffleCreationForm = ({ user }) => {
  const [raffleDetails, setRaffleDetails] = useState({
    name: '',
    description: '',
    raffleType: '',
    entryDate: '',
    entryTime: '',
    maxEntriesPerAddress: '',
    enableAgeGate: false,
    minimumAge: '',
    restrictedCountries: [],
    includeBrand: false,
    selectedMyBrand: '',
    selectedSharedBrand: '',
  });
  const [errors, setErrors] = useState({});
  const [monthlyRaffleCount, setMonthlyRaffleCount] = useState(0);
  const [monthResetDate, setMonthResetDate] = useState(null);

  // Premium status
  const [isPremium, setIsPremium] = useState(false);

  // For the user’s own brands
  const [brands, setBrands] = useState([]);
  const [brandsLoading, setBrandsLoading] = useState(false);
  const [brandsError, setBrandsError] = useState(null);

  // For the user’s shared brands
  const [sharedBrands, setSharedBrands] = useState([]);
  const [sharedBrandsLoading, setSharedBrandsLoading] = useState(false);
  const [sharedBrandsError, setSharedBrandsError] = useState(null);

  const navigate = useNavigate();

  // ------------------------------------------------------------------------------
  // 1) Redirect if user not logged in
  // ------------------------------------------------------------------------------
  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user, navigate]);

  // ------------------------------------------------------------------------------
  // 2) Check subscription & fetch monthly raffle count
  // ------------------------------------------------------------------------------
  useEffect(() => {
    if (!user) return;

    const fetchMonthlyRaffleCount = async () => {
      try {
        const currentMonthStart = new Date();
        currentMonthStart.setDate(1);
        currentMonthStart.setHours(0, 0, 0, 0);

        const q = query(
          collection(firestore, 'raffles'),
          where('ownerId', '==', user.uid),
          where('createdAt', '>=', currentMonthStart)
        );

        const querySnapshot = await getDocs(q);
        setMonthlyRaffleCount(querySnapshot.size);

        if (querySnapshot.size > 0) {
          const createdAtTimestamp = querySnapshot.docs[0].data().createdAt;
          if (createdAtTimestamp?.toDate) {
            const resetDate = new Date(createdAtTimestamp.toDate());
            resetDate.setMonth(resetDate.getMonth() + 1);
            setMonthResetDate(resetDate);
          }
        } else {
          const defaultResetDate = new Date();
          defaultResetDate.setDate(1);
          defaultResetDate.setMonth(defaultResetDate.getMonth() + 1);
          defaultResetDate.setHours(0, 0, 0, 0);
          setMonthResetDate(defaultResetDate);
        }
      } catch (error) {
        console.error('Error fetching monthly raffle count:', error);
      }
    };

    const listenToPremiumStatus = () => {
      const subscriptionsRef = collection(firestore, 'users', user.uid, 'subscriptions');
      const qSubscriptions = query(subscriptionsRef, where('status', '==', 'active'));

      return onSnapshot(
        qSubscriptions,
        (querySnapshot) => {
          setIsPremium(!querySnapshot.empty);
        },
        (error) => {
          console.error('Error listening to subscription documents:', error);
        }
      );
    };

    fetchMonthlyRaffleCount();
    const unsubscribePremium = listenToPremiumStatus();

    return () => unsubscribePremium();
  }, [user]);

  // ------------------------------------------------------------------------------
  // 3) Fetch user’s own brands if Premium
  // ------------------------------------------------------------------------------
  useEffect(() => {
    if (!user || !isPremium) {
      setBrands([]);
      return;
    }

    setBrandsLoading(true);
    const brandsRef = collection(firestore, 'users', user.uid, 'brands');
    const unsubscribe = onSnapshot(
      brandsRef,
      (querySnapshot) => {
        const userBrands = [];
        querySnapshot.forEach((docSnap) => {
          userBrands.push({ id: docSnap.id, ownerUid: user.uid, ...docSnap.data() });
        });
        setBrands(userBrands);
        setBrandsLoading(false);
      },
      (error) => {
        console.error('Error fetching brands:', error);
        setBrandsError('Failed to fetch brands.');
        setBrandsLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user, isPremium]);

  // ------------------------------------------------------------------------------
  // 4) Fetch brands shared with the user if Premium
  // ------------------------------------------------------------------------------
  useEffect(() => {
    if (!user || !isPremium) {
      setSharedBrands([]);
      return;
    }

    const fetchSharedBrands = async () => {
      try {
        setSharedBrandsLoading(true);

        // 1) Find all docs in "sharedBrandPermissions" for current user
        const sharedPermsRef = collection(firestore, 'sharedBrandPermissions');
        const qShared = query(sharedPermsRef, where('sharedWithUid', '==', user.uid));
        const sharedSnap = await getDocs(qShared);

        if (sharedSnap.empty) {
          setSharedBrands([]);
          setSharedBrandsLoading(false);
          return;
        }

        // 2) For each permission doc, fetch the actual brand from brandOwnerUid's subcollection
        const tempSharedBrands = [];

        for (const permDoc of sharedSnap.docs) {
          const { brandOwnerUid, brandId, permissionLevel } = permDoc.data();

          // If you only want to show brand in dropdown if user has certain permission, 
          // do any checks here. For this example, we allow any brand with a valid permission doc.
          const brandRef = doc(firestore, 'users', brandOwnerUid, 'brands', brandId);
          const brandSnap = await getDoc(brandRef);
          if (brandSnap.exists()) {
            const brandData = brandSnap.data();
            tempSharedBrands.push({
              id: brandId,
              ownerUid: brandOwnerUid,
              permissionLevel: permissionLevel,
              ...brandData,
            });
          }
        }

        setSharedBrands(tempSharedBrands);
        setSharedBrandsLoading(false);
      } catch (error) {
        console.error('Error fetching shared brands:', error);
        setSharedBrandsError('Failed to fetch shared brands.');
        setSharedBrandsLoading(false);
      }
    };

    fetchSharedBrands();
  }, [user, isPremium]);

  // ------------------------------------------------------------------------------
  // 5) Handlers
  // ------------------------------------------------------------------------------
  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setRaffleDetails((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Switch toggling whether to include a brand at all
  const handleToggleIncludeBrand = (e) => {
    const { checked } = e.target;
    setRaffleDetails((prev) => ({
      ...prev,
      includeBrand: checked,
      // When toggling OFF brand usage, clear out brand selections
      selectedMyBrand: checked ? prev.selectedMyBrand : '',
      selectedSharedBrand: checked ? prev.selectedSharedBrand : '',
    }));
  };

  // If user selects "my brand," clear any selected shared brand
  const handleSelectMyBrand = (e) => {
    const { value } = e.target;
    setRaffleDetails((prev) => ({
      ...prev,
      selectedMyBrand: value,
      // Clear out shared brand so only one can be chosen
      selectedSharedBrand: '',
    }));
  };

  // If user selects "shared brand," clear any selected my brand
  const handleSelectSharedBrand = (e) => {
    const { value } = e.target;
    setRaffleDetails((prev) => ({
      ...prev,
      selectedSharedBrand: value,
      // Clear out my brand so only one can be chosen
      selectedMyBrand: '',
    }));
  };

  // Validate form
  const validateForm = () => {
    const {
      name,
      raffleType,
      entryDate,
      entryTime,
      maxEntriesPerAddress,
      enableAgeGate,
      minimumAge,
      includeBrand,
      selectedMyBrand,
      selectedSharedBrand,
    } = raffleDetails;

    const newErrors = {};

    if (!name.trim()) newErrors.name = 'List name is required.';
    if (!raffleType) newErrors.raffleType = 'Please select a list type.';
    if (!entryDate) newErrors.entryDate = 'Entry deadline date is required.';
    if (!entryTime) newErrors.entryTime = 'Entry deadline time is required.';
    if (
      !maxEntriesPerAddress ||
      isNaN(maxEntriesPerAddress) ||
      parseInt(maxEntriesPerAddress, 10) <= 0
    ) {
      newErrors.maxEntriesPerAddress = 'Please enter a valid number greater than 0.';
    }
    if (enableAgeGate) {
      if (!minimumAge || isNaN(minimumAge) || parseInt(minimumAge, 10) < 0) {
        newErrors.minimumAge = 'Please enter a valid minimum age.';
      }
    }
    // If the user toggled "include brand", ensure they pick exactly one brand 
    if (includeBrand) {
      if (!selectedMyBrand && !selectedSharedBrand) {
        newErrors.brandSelection = 'Please select either a My Brand or a Shared Brand.';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Create raffle
  const createRaffle = async () => {
    // Enforce monthly limit if not premium
    if (!isPremium && monthlyRaffleCount >= 5) {
      alert('You have reached the monthly limit of 5 raffles. Upgrade to premium for unlimited raffles.');
      return;
    }

    if (!validateForm()) {
      return;
    }

    const {
      name,
      description,
      raffleType,
      entryDate,
      entryTime,
      maxEntriesPerAddress,
      enableAgeGate,
      minimumAge,
      restrictedCountries,
      includeBrand,
      selectedMyBrand,
      selectedSharedBrand,
    } = raffleDetails;

    // Combine date/time into an ISO string
    const entryDeadline = new Date(`${entryDate}T${entryTime}`).toISOString();

    // Base raffle data
    const raffleData = {
      name,
      description,
      raffleType,
      entryDeadline,
      maxEntriesPerAddress: parseInt(maxEntriesPerAddress, 10),
      ownerId: user.uid,
      createdAt: new Date(),
      enableAgeGate,
      minimumAge: enableAgeGate ? parseInt(minimumAge, 10) : null,
      restrictedCountries,
    };

    // If user is premium and toggled brand usage
    if (isPremium && includeBrand) {
      // If they picked a brand from "my brands"
      if (selectedMyBrand) {
        raffleData.selectedBrandInfo = {
          brandId: selectedMyBrand,
          brandOwnerUid: user.uid,
        };
      }
      // Or if they picked from "shared brands"
      else if (selectedSharedBrand) {
        const chosen = sharedBrands.find((b) => b.id === selectedSharedBrand);
        if (chosen) {
          raffleData.selectedBrandInfo = {
            brandId: chosen.id,
            brandOwnerUid: chosen.ownerUid,
          };
        }
      }
    }

    try {
      const docRef = await addDoc(collection(firestore, 'raffles'), raffleData);
      navigate(`/List/${docRef.id}`);
    } catch (error) {
      console.error('Error creating Entry Form:', error);
      alert('Failed to create Entry Form. Please try again.');
    }
  };

  // ------------------------------------------------------------------------------
  // 6) If user not authenticated at all, bail out
  // ------------------------------------------------------------------------------
  if (!user) {
    return (
      <Box sx={{ padding: '2rem' }}>
        <Typography variant="body1">Please sign in to create an Address List Entry Form.</Typography>
      </Box>
    );
  }

  // ------------------------------------------------------------------------------
  // 7) If user is FREE & monthly limit reached, show upgrade
  // ------------------------------------------------------------------------------
  if (!isPremium && monthlyRaffleCount >= 5) {
    return (
      <Box sx={{ padding: { xs: '1rem', md: '2rem' } }}>
        <Alert severity="info" sx={{ marginBottom: '1rem' }}>
          You have reached the monthly limit of 5 Entry Forms & Lists. Upgrade to premium for unlimited creation.
          <Tooltip
            title={
              monthResetDate
                ? `Your monthly limit will reset on ${new Date(monthResetDate).toLocaleDateString()}`
                : 'Your reset date could not be determined.'
            }
            placement="top"
          >
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <HandleUpgradeToPremiumButton user={user} />
        </Alert>

        <Typography variant="h5" gutterBottom>
          Create a Bitcoin, Ethereum, or Solana Address Entry Form to Share with your Communities
        </Typography>

        {/* Disabled form preview */}
        <form noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="List Name"
                disabled
                fullWidth
                helperText="Upgrade to premium to enable this field."
              />
            </Grid>
            {/* Similarly disable everything else */}
            <Grid item xs={12}>
              <Button variant="contained" fullWidth disabled>
                Create Wallet Collector
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  }

  // ------------------------------------------------------------------------------
  // 8) Otherwise, show the real form
  // ------------------------------------------------------------------------------
  return (
    <Box sx={{ padding: { xs: '1rem', md: '2rem' } }}>
      <Typography variant="h5" gutterBottom>
        Create a Bitcoin, Ethereum, or Solana Address Entry Form to Share with your Communities
      </Typography>
      <form noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="List Name"
              name="name"
              value={raffleDetails.name}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
              error={Boolean(errors.name)}
              helperText={errors.name}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Description"
              name="description"
              value={raffleDetails.description}
              onChange={handleInputChange}
              multiline
              rows={4}
              fullWidth
              margin="normal"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              margin="normal"
              required
              error={Boolean(errors.raffleType)}
            >
              <InputLabel id="raffle-type-label">List Type</InputLabel>
              <Select
                labelId="raffle-type-label"
                name="raffleType"
                value={raffleDetails.raffleType}
                onChange={handleInputChange}
                label="List Type"
              >
                <MenuItem value="">
                  <em>--Select--</em>
                </MenuItem>
                <MenuItem value="ethereum">Ethereum</MenuItem>
                <MenuItem value="solana">Solana</MenuItem>
                <MenuItem value="bitcoin">Bitcoin</MenuItem>
              </Select>
              {errors.raffleType && (
                <FormHelperText>{errors.raffleType}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Max Entries per Address"
              name="maxEntriesPerAddress"
              value={raffleDetails.maxEntriesPerAddress}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              error={Boolean(errors.maxEntriesPerAddress)}
              helperText={errors.maxEntriesPerAddress}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Entry Deadline Date"
              type="date"
              name="entryDate"
              value={raffleDetails.entryDate}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(errors.entryDate)}
              helperText={errors.entryDate}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Entry Deadline Time"
              type="time"
              name="entryTime"
              value={raffleDetails.entryTime}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(errors.entryTime)}
              helperText={errors.entryTime}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="enableAgeGate"
                  checked={raffleDetails.enableAgeGate}
                  onChange={handleInputChange}
                />
              }
              label="Enable Age Restriction"
            />
          </Grid>

          {raffleDetails.enableAgeGate && (
            <Grid item xs={12} sm={6}>
              <TextField
                label="Minimum Age"
                name="minimumAge"
                value={raffleDetails.minimumAge}
                onChange={handleInputChange}
                required
                fullWidth
                margin="normal"
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                error={Boolean(errors.minimumAge)}
                helperText={errors.minimumAge}
              />
            </Grid>
          )}

          {/* PREMIUM BRAND SECTION */}
          {isPremium && (
            <>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={raffleDetails.includeBrand}
                        onChange={handleToggleIncludeBrand}
                        name="includeBrand"
                        color="primary"
                      />
                    }
                    label="Include a Brand in this List Entry Form"
                  />
                  {errors.brandSelection && (
                    <FormHelperText error>{errors.brandSelection}</FormHelperText>
                  )}
                </FormGroup>
              </Grid>

              {raffleDetails.includeBrand && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">
                      Select only one: My Brand or a Shared Brand
                    </Typography>
                  </Grid>

                  {/* Select from MY BRANDS */}
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      margin="normal"
                      disabled={Boolean(raffleDetails.selectedSharedBrand)} // disable if shared brand was chosen
                      error={Boolean(errors.brandSelection)}
                    >
                      <InputLabel id="my-brand-label">Select My Brand</InputLabel>
                      <Select
                        labelId="my-brand-label"
                        name="selectedMyBrand"
                        value={raffleDetails.selectedMyBrand}
                        onChange={handleSelectMyBrand}
                        label="Select My Brand"
                      >
                        <MenuItem value="">
                          <em>--None--</em>
                        </MenuItem>
                        {brandsLoading ? (
                          <MenuItem disabled>
                            <em>Loading your brands...</em>
                          </MenuItem>
                        ) : brandsError ? (
                          <MenuItem disabled>
                            <em>{brandsError}</em>
                          </MenuItem>
                        ) : brands.length === 0 ? (
                          <MenuItem disabled>
                            <em>No brands found</em>
                          </MenuItem>
                        ) : (
                          brands.map((brand) => (
                            <MenuItem key={brand.id} value={brand.id}>
                              {brand.name}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                      <FormHelperText>
                        {raffleDetails.selectedSharedBrand
                          ? 'Shared brand is currently selected, so this is disabled.'
                          : 'Pick a brand you own, or leave blank to select a shared brand.'}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  {/* Select from SHARED BRANDS */}
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      margin="normal"
                      disabled={Boolean(raffleDetails.selectedMyBrand)} // disable if my brand was chosen
                      error={Boolean(errors.brandSelection)}
                    >
                      <InputLabel id="shared-brand-label">Select Shared Brand</InputLabel>
                      <Select
                        labelId="shared-brand-label"
                        name="selectedSharedBrand"
                        value={raffleDetails.selectedSharedBrand}
                        onChange={handleSelectSharedBrand}
                        label="Select Shared Brand"
                      >
                        <MenuItem value="">
                          <em>--None--</em>
                        </MenuItem>
                        {sharedBrandsLoading ? (
                          <MenuItem disabled>
                            <em>Loading shared brands...</em>
                          </MenuItem>
                        ) : sharedBrandsError ? (
                          <MenuItem disabled>
                            <em>{sharedBrandsError}</em>
                          </MenuItem>
                        ) : sharedBrands.length === 0 ? (
                          <MenuItem disabled>
                            <em>No shared brands found</em>
                          </MenuItem>
                        ) : (
                          sharedBrands.map((brand) => (
                            <MenuItem key={brand.id} value={brand.id}>
                              {brand.name}
                              {brand.permissionLevel && ` (${brand.permissionLevel})`}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                      <FormHelperText>
                        {raffleDetails.selectedMyBrand
                          ? 'Your brand is currently selected, so this is disabled.'
                          : 'Pick a shared brand you have permission to use, or leave blank to select your brand.'}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </>
              )}
            </>
          )}

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={createRaffle}
              fullWidth
              sx={{ marginTop: '1rem' }}
            >
              Create Wallet Collector List Entry Form
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default RaffleCreationForm;
