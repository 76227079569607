/**
 * @file   BrandSharePermissionsDialog.js
 * @brief  Dialog for brand owners to manage share permissions (view/edit) on their brand.
 * @date   2025-01-25
 */

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Typography,
  IconButton,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { firestore } from '../firebase';
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  serverTimestamp,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import CloseIcon from '@mui/icons-material/Close';

const BrandSharePermissionsDialog = ({
  open,
  onClose,
  brandOwnerUid,
  brandId,
  currentUserUid, // we might need to ensure the UI doesn't let the owner remove themselves
}) => {
  const [searchUsername, setSearchUsername] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);

  // brand shares
  const [sharedUsers, setSharedUsers] = useState([]);
  const [loadingSharedUsers, setLoadingSharedUsers] = useState(true);

  const [sharing, setSharing] = useState(false);
  const [error, setError] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  // For selecting permission level
  const [selectedPermissionLevel, setSelectedPermissionLevel] = useState('view');

  useEffect(() => {
    if (!open) return;

    const fetchSharedUsers = async () => {
      setLoadingSharedUsers(true);
      setError('');
      setSuccessMsg('');

      try {
        // get all docs in 'sharedBrandPermissions' for this brand
        const sharedBrandPermsRef = collection(firestore, 'sharedBrandPermissions');
        const qShared = query(
          sharedBrandPermsRef,
          where('brandId', '==', brandId),
          where('brandOwnerUid', '==', brandOwnerUid)
        );
        const sharedSnap = await getDocs(qShared);

        const users = [];
        for (const docSnap of sharedSnap.docs) {
          const permData = docSnap.data();
          const { sharedWithUid, permissionLevel } = permData;

          // fetch their username
          const usernameQuery = query(
            collection(firestore, 'usernames'),
            where('uid', '==', sharedWithUid)
          );
          const usernameSnap = await getDocs(usernameQuery);
          if (!usernameSnap.empty) {
            const usernameData = usernameSnap.docs[0].data();
            users.push({
              permissionId: docSnap.id,
              username: usernameData.username,
              sharedWithUid,
              permissionLevel: permissionLevel || 'view',
            });
          }
        }
        setSharedUsers(users);
      } catch (err) {
        console.error('Error fetching shared brand users:', err);
        setError('Failed to load shared users.');
      } finally {
        setLoadingSharedUsers(false);
      }
    };

    fetchSharedUsers();
  }, [open, brandId, brandOwnerUid]);

  const handleClose = () => {
    // Reset states on close
    setSearchUsername('');
    setSearchResults([]);
    setError('');
    setSuccessMsg('');
    setSelectedPermissionLevel('view');
    onClose();
  };

  const handleSearch = async () => {
    setError('');
    setSuccessMsg('');

    if (!searchUsername.trim()) {
      setError('Please enter a username to search.');
      return;
    }

    setSearching(true);
    try {
      const usernamesRef = collection(firestore, 'usernames');
      const qUser = query(
        usernamesRef,
        where('username', '==', searchUsername.trim().toLowerCase())
      );
      const querySnapshot = await getDocs(qUser);
      const results = querySnapshot.docs.map((doc) => doc.data());
      setSearchResults(results);

      if (results.length === 0) {
        setError('No users found with that username.');
      }
    } catch (err) {
      console.error('Error searching usernames:', err);
      setError('An error occurred while searching. Please try again.');
    } finally {
      setSearching(false);
    }
  };

  const handleShare = async (shareWithUid) => {
    setError('');
    setSuccessMsg('');
    setSharing(true);

    try {
      // Check if permission doc already exists
      const sharedBrandPermsRef = collection(firestore, 'sharedBrandPermissions');
      const qCheck = query(
        sharedBrandPermsRef,
        where('brandId', '==', brandId),
        where('brandOwnerUid', '==', brandOwnerUid),
        where('sharedWithUid', '==', shareWithUid)
      );
      const existingSnapshot = await getDocs(qCheck);

      if (!existingSnapshot.empty) {
        setError('Permissions already shared with this user.');
        return;
      }

      // Add new doc
      await addDoc(sharedBrandPermsRef, {
        brandId,
        brandOwnerUid,
        sharedWithUid: shareWithUid,
        permissionLevel: selectedPermissionLevel, // store "view" or "edit"
        createdAt: serverTimestamp(),
      });

      setSuccessMsg('Brand permissions shared successfully!');
      setSearchUsername('');
      setSearchResults([]);

      // Refresh the shared users list
      // (could call the same fetch logic as above)
      const qShared = query(
        sharedBrandPermsRef,
        where('brandId', '==', brandId),
        where('brandOwnerUid', '==', brandOwnerUid)
      );
      const sharedSnap = await getDocs(qShared);

      const users = [];
      for (const docSnap of sharedSnap.docs) {
        const permData = docSnap.data();
        const { sharedWithUid, permissionLevel } = permData;

        // fetch username
        const usernameQuery = query(
          collection(firestore, 'usernames'),
          where('uid', '==', sharedWithUid)
        );
        const usernameSnap = await getDocs(usernameQuery);
        if (!usernameSnap.empty) {
          const usernameData = usernameSnap.docs[0].data();
          users.push({
            permissionId: docSnap.id,
            username: usernameData.username,
            sharedWithUid,
            permissionLevel,
          });
        }
      }
      setSharedUsers(users);
    } catch (err) {
      console.error('Error sharing brand permissions:', err);
      setError('Failed to share brand. Please try again.');
    } finally {
      setSharing(false);
    }
  };

  const handleRevoke = async (permissionId, sharedWithUid) => {
    setError('');
    setSuccessMsg('');

    // Prevent revoking from the brand owner themself
    if (sharedWithUid === brandOwnerUid) {
      setError("You cannot revoke the brand owner from their own brand.");
      return;
    }

    try {
      const docRef = doc(firestore, 'sharedBrandPermissions', permissionId);
      await deleteDoc(docRef);

      setSuccessMsg('Permissions revoked successfully!');
      setSharedUsers((prev) =>
        prev.filter((user) => user.permissionId !== permissionId)
      );
    } catch (err) {
      console.error('Error revoking brand permissions:', err);
      setError('Failed to revoke permissions. Please try again.');
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Manage Brand Permissions
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Typography variant="h6" gutterBottom>
          Share this Brand
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <TextField
            label="Search Username"
            variant="outlined"
            fullWidth
            value={searchUsername}
            onChange={(e) => setSearchUsername(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleSearch();
            }}
            disabled={searching || sharing}
          />

          <FormControl fullWidth>
            <InputLabel id="brand-share-permission-label">Permission</InputLabel>
            <Select
              labelId="brand-share-permission-label"
              value={selectedPermissionLevel}
              label="Permission"
              onChange={(e) => setSelectedPermissionLevel(e.target.value)}
              disabled={sharing}
            >
              <MenuItem value="view">View</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          disabled={searching || sharing}
          fullWidth
        >
          {searching ? <CircularProgress size={24} /> : 'Search'}
        </Button>

        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        {successMsg && (
          <Typography color="success.main" sx={{ mt: 2 }}>
            {successMsg}
          </Typography>
        )}

        {/* Search Results */}
        {searchResults.length > 0 && (
          <List sx={{ mt: 2 }}>
            {searchResults.map((userData, index) => (
              <ListItem key={index} disabled={sharing}>
                <ListItemText primary={userData.username} />
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => handleShare(userData.uid)}
                  disabled={sharing}
                >
                  {sharing ? <CircularProgress size={16} /> : 'Share'}
                </Button>
              </ListItem>
            ))}
          </List>
        )}

        <Box sx={{ my: 4 }}>
          <hr />
        </Box>

        <Typography variant="h6" gutterBottom>
          Current Shared Users
        </Typography>
        {loadingSharedUsers ? (
          <CircularProgress />
        ) : sharedUsers.length === 0 ? (
          <Typography variant="body2">
            No users have access to this brand.
          </Typography>
        ) : (
          <List>
            {sharedUsers.map((sharedUser) => (
              <ListItem key={sharedUser.permissionId}>
                <ListItemText
                  primary={`${sharedUser.username}`}
                  secondary={`Permission: ${sharedUser.permissionLevel}`}
                />
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={() =>
                    handleRevoke(sharedUser.permissionId, sharedUser.sharedWithUid)
                  }
                >
                  Revoke
                </Button>
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} disabled={sharing}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BrandSharePermissionsDialog;
