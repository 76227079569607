/**
 * @file        /App.js
 * @version     3.0.0
 * @author      Trevor Bissonette
 * @date        2024-04-27
 * @lastModified 2025-01-24
 * 
 * @summary
 * Serves as the root component for the WalletCollector application. Manages routing and user authentication state,
 * renders the navigation bar and footer, and defines routes to specific pages such as the homepage, profile page,
 * List creation, List details, and other informational pages.
 * 
 * @description
 * The `App` component is the entry point of the WalletCollector application. It utilizes React Router for client-side
 * routing and Firebase for managing user authentication states. Upon mounting, it sets up an authentication state observer
 * to track user login status. The component defines various routes corresponding to different pages within the application,
 * ensuring seamless navigation and access control based on user roles and permissions.
 * 
 * Key Features:
 * - **Routing Management:** Defines routes for the homepage, profile page, List creation, List details, contact us, 
 *   privacy policy, terms of service, about page, and brand management.
 * - **User Authentication:** Monitors and manages user authentication states using Firebase, enabling conditional rendering 
 *   based on user login status.
 * - **Navigation and Footer:** Renders a consistent navigation bar and footer across all pages for improved user experience.
 * - **Redirection Handling:** Includes redirection logic to ensure users are directed to appropriate pages based on URL parameters.
 * - **Brand Integration:** Incorporates brand-specific pages, allowing users to manage their brands within the application.
 * 
 * @license
 * © 2025 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 * 
 * @changelog
 * - **3.0.0**: Added a new route for managing brands (`/mybrands`) and integrated the Footer component.
 * - **2.1.0**: Implemented redirection from `/raffle/:id` to `/list/:id` for consistent URL structure.
 * - **2.0.0**: Enhanced documentation and updated author information.
 * - **1.0.0**: Initial creation of the App component with basic routing and authentication handling.
 */

 // src/App.js

import React, { useEffect, useState } from 'react';
import { auth } from './firebase';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer'; // Import Footer
import HomePage from './pages/HomePage';
import ProfilePage from './pages/ProfilePage';
import RafflePage from './pages/RafflePage';
import RaffleEntryPage from './pages/RaffleEntryPage';
import RaffleCreationForm from './components/RaffleCreationForm';
import ContactUs from './pages/ContactUs';
import PrivacyPolicyPage from './pages/policy';
import TermsOfServicePage from './pages/terms';
import AboutPage from './pages/about';
import MyBrands from './pages/MyBrands'; // Import MyBrands
import RefundPolicyPage from './pages/refundpolicypage';

/**
 * @component
 * App
 * 
 * The root component of the WalletCollector application. It sets up routing for different pages, manages user authentication
 * state, and ensures that the navigation bar and footer are consistently displayed across all pages.
 * 
 * @returns {JSX.Element} The rendered App component.
 */
function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    /**
     * Sets up an authentication state observer using Firebase. Updates the `user` state based on the authentication status.
     * Cleans up the observer on component unmount to prevent memory leaks.
     */
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
    });

    // Clean up subscription on unmount
    return () => unsubscribe();
  }, []);

  /**
   * Component to handle redirection from `/raffle/:id` to `/list/:id`.
   * Ensures that legacy or alternative URLs are correctly redirected to the current route structure.
   * 
   * @component
   * @returns {JSX.Element} A `<Navigate>` component that redirects to the appropriate raffle list page.
   */
  const RedirectRaffleToList = () => {
    const { id } = useParams();
    return <Navigate to={`/list/${id}`} replace />;
  };

  return (
    <Router>
      {/* Navigation Bar */}
      <Navbar user={user} />

      {/* Define Application Routes */}
      <Routes>
        {/* Homepage Route */}
        <Route path="/" element={<HomePage user={user} />} />

        {/* Profile Page Route */}
        <Route path="/profile" element={<ProfilePage user={user} />} />

        {/* Raffle Creation Form Route */}
        <Route path="/create" element={<RaffleCreationForm user={user} />} />

        {/* Raffle Details Page Route */}
        <Route path="/List/:id" element={<RafflePage user={user} />} />

        {/* Raffle Entry Page Route */}
        <Route path="/List/entry/:id" element={<RaffleEntryPage user={user} />} />

        {/* Redirection from `/raffle/:id` to `/list/:id` */}
        <Route path="/raffle/:id" element={<RedirectRaffleToList />} />

        {/* Contact Us Page Route */}
        <Route path="/contactUs" element={<ContactUs />} />

        {/* Privacy Policy Page Route */}
        <Route path="/policy" element={<PrivacyPolicyPage />} />

        {/* Refund Policy Page Route */}
        <Route path="/refund-policy" element={<RefundPolicyPage />} />

        {/* Terms of Service Page Route */}
        <Route path="/terms" element={<TermsOfServicePage />} />

        {/* About Page Route */}
        <Route path="/about" element={<AboutPage />} />

        {/* My Brands Page Route */}
        <Route path="/mybrands" element={<MyBrands user={user} />} />

        {/* Fallback Route for Undefined Paths */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>

      {/* Footer */}
      <Footer />
    </Router>
  );
}

export default App;
