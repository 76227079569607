/**
 * @file        /src/components/RaffleEntriesList.js
 * @version     2.1.0
 * @author      Trevor Bissonette
 * @date        2024-04-27
 * @lastModified 2025-01-25
 * 
 * @summary
 * Manages and displays List entries for List creators within the WalletCollector application. Provides functionalities
 * to view, search, copy, and download entrant information with real-time Firestore integration.
 * 
 * @description
 * The `RaffleEntriesList` component allows List creators to efficiently view and manage their List entries. It retrieves
 * entries from Firebase Firestore in real-time and offers features such as searching by wallet address, copying all addresses
 * to the clipboard, and downloading entrant data in various formats (.txt, .json, .csv). The component ensures a responsive
 * user interface using Material-UI components and handles loading and error states gracefully.
 * 
 * Key Features:
 * - **Real-Time Data Fetching:** Listens to Firestore for live updates to List entries.
 * - **Search Functionality:** Enables searching for entrants by wallet address.
 * - **Data Export:** Allows downloading entrant addresses in multiple formats.
 * - **Clipboard Integration:** Provides a button to copy all entrant addresses.
 * - **Responsive Design:** Utilizes Material-UI for a responsive and intuitive UI.
 * - **Error Handling:** Displays error messages when data fetching fails.
 * 
 * @license
 * © 2025 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 * 
 * @changelog
 * - **2.1.0**: Added inline documentation for improved readability and maintainability. Optimized state management and event handlers for better scalability.
 * - **2.0.0**: Refactored component for enhanced performance and scalability. Improved search functionality and added support for multiple download file types.
 * - **1.0.0**: Initial creation of the RaffleEntriesList component with basic entry fetching and display capabilities.
 */

import React, { useEffect, useState } from 'react';
import { firestore } from '../firebase';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Alert,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Paper,
  Grid
} from '@mui/material';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import DownloadIcon from '@mui/icons-material/Download';

/**
 * RaffleEntriesList Component
 * 
 * @param {string} raffleId - The unique identifier for the raffle.
 * @param {boolean} isRaffleOwner - Indicates if the current user is the raffle owner.
 */
const RaffleEntriesList = ({ raffleId, isRaffleOwner }) => {
  // State to hold raffle entries
  const [entries, setEntries] = useState([]);
  
  // Loading and error states
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Search-related states
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  
  // Clipboard and download states
  const [copied, setCopied] = useState(false);
  const [fileType, setFileType] = useState('.txt');

  /**
   * Fetches raffle entries from Firestore in real-time.
   */
  useEffect(() => {
    if (!raffleId) {
      setEntries([]);
      setLoading(false);
      return;
    }

    const entriesRef = collection(firestore, 'raffleEntries');
    const q = query(
      entriesRef,
      where('raffleId', '==', raffleId),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const entriesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt
            ? doc.data().createdAt.toDate().toLocaleString()
            : 'N/A',
        }));
        setEntries(entriesData);
        setLoading(false);
      },
      (error) => {
        console.error('Error fetching entries:', error);
        setError('Failed to load raffle entries.');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [raffleId]);

  /**
   * Searches for an entrant by wallet address.
   */
  const handleSearch = () => {
    const result = entries.find(
      (entry) => entry.address === searchQuery.trim()
    );
    setSearchResult(result);
  };

  /**
   * Copies all entrant addresses to the clipboard.
   */
  const handleCopyAddresses = async () => {
    try {
      const addresses = entries.map((entry) => entry.address).join('\n');
      await navigator.clipboard.writeText(addresses);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy addresses:', err);
    }
  };

  /**
   * Downloads entrant addresses in the selected file format.
   */
  const handleDownload = () => {
    const addresses = entries.map((entry) => entry.address);
    let fileContent = '';

    switch (fileType) {
      case '.json':
        fileContent = JSON.stringify(addresses, null, 2);
        break;
      case '.csv':
        fileContent = ['address', ...addresses].join('\n');
        break;
      default:
        fileContent = addresses.join('\n');
    }

    const blob = new Blob([fileContent], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `raffle_entries${fileType}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  if (loading) {
    return (
      <Box sx={{ textAlign: 'center', padding: '1rem' }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ marginTop: '1rem' }}>
          Loading raffle entries...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: '1rem' }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (entries.length === 0) {
    return (
      <Box sx={{ padding: '1rem' }}>
        <Typography variant="body1">No entries yet.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '1rem' }}>
      <Typography variant="h5" gutterBottom>
        Your Entrant List
      </Typography>

      <Paper sx={{ p: 2, mb: 3 }} elevation={3}>
        <Typography variant="h6" gutterBottom>
          Entrant Addresses
        </Typography>

        <TextField
          multiline
          fullWidth
          rows={entries.length > 10 ? 10 : entries.length || 1}
          value={entries.map((entry) => entry.address).join('\n')}
          variant="outlined"
          inputProps={{
            wrap: 'off',
            readOnly: true,
            style: {
              whiteSpace: 'pre',
            },
          }}
          sx={{
            '& .MuiInputBase-input': {
              overflowX: 'auto',
              overflowY: 'auto',
            },
          }}
        />

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={isRaffleOwner ? 4 : 6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleCopyAddresses}
              startIcon={copied ? <CheckIcon /> : <ContentCopyIcon />}
            >
              {copied ? 'Copied!' : 'Copy Addresses'}
            </Button>
          </Grid>

          {isRaffleOwner && (
            <>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    id="file-type-label"
                    sx={{ whiteSpace: 'normal', lineHeight: 1.2 }}
                  >
                    File Type for download
                  </InputLabel>
                  <Select
                    labelId="file-type-label"
                    value={fileType}
                    onChange={(e) => setFileType(e.target.value)}
                    label="File Type for download"
                  >
                    <MenuItem value=".txt">.txt</MenuItem>
                    <MenuItem value=".json">.json</MenuItem>
                    <MenuItem value=".csv">.csv</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={handleDownload}
                  startIcon={<DownloadIcon />}
                >
                  Download
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>

      {isRaffleOwner && (
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Search Entries
          </Typography>
          <TextField
            label="Search by Wallet Address"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            fullWidth
          >
            Search
          </Button>
          {searchResult && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1">
                <strong>Address:</strong> {searchResult.address}
              </Typography>
              <Typography variant="body1">
                <strong>Twitter Handle:</strong>{' '}
                {searchResult.twitterHandle || 'N/A'}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default RaffleEntriesList;
