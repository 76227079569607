/**
 * @file        /src/components/RaffleCreationForm.js
 * @version     2.1.0
 * @author      Trevor Bissonette
 * @date        2024-04-27
 * @lastModified 2025-01-24
 * 
 * @summary
 * Provides a form component for creating Lists within the WalletCollector application. Handles form validation,
 * saves List data to Firestore, and generates shareable links for created Lists. Integrates premium features
 * such as brand inclusion for subscribed users.
 * 
 * @description
 * The `RaffleCreationForm` component facilitates the creation of Lists by authenticated users in the WalletCollector
 * application. It encompasses comprehensive form validation to ensure data integrity, interacts with Firebase Firestore
 * to store List details, and generates shareable links for users to distribute their Lists. Additionally, the component
 * incorporates premium functionalities, allowing premium users to include their brands in Lists, thereby enhancing the
 * user experience and offering advanced features to subscribed members.
 * 
 * Key Features:
 * - **Form Validation:** Implements robust validation mechanisms for all input fields, ensuring that users provide valid
 *   and complete information before submitting the form.
 * - **Firestore Integration:** Saves List details to Firebase Firestore, enabling persistent storage and real-time updates.
 * - **Shareable Links:** Generates unique, shareable links for each created List, allowing users to easily distribute their Lists.
 * - **Premium Features:** Supports premium functionalities such as including brands in Lists, accessible to users with active
 *   premium subscriptions.
 * - **Responsive Design:** Utilizes Material-UI (MUI) components to ensure the form is responsive and user-friendly across various devices.
 * - **Subscription Monitoring:** Listens to Firestore subscription documents to determine the user's premium status, dynamically adjusting
 *   available form options based on subscription.
 * - **User Feedback:** Provides real-time feedback and alerts to users during the List creation process, enhancing usability and engagement.
 * 
 * @license
 * © 2025 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 * 
 * @changelog
 * - **2.1.0**: Added premium feature support for brand inclusion and enhanced form validation.
 * - **1.0.0**: Initial creation of the RaffleCreationForm component with basic form handling and Firestore integration.
 */

import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase';
import {
  addDoc,
  collection,
  query,
  where,
  getDocs,
  onSnapshot, // Import onSnapshot for real-time updates
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

// Import the HandleUpgradeToPremiumButton component
import HandleUpgradeToPremiumButton from '../components/HandleUpgradeToPremiumButton';

import {
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  Alert,
  Tooltip,
  IconButton,
  Switch,
  FormGroup,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

/**
 * @component
 * RaffleCreationForm
 * 
 * Renders a form that allows authenticated users to create Raffles. Handles input validation, saves Raffle data
 * to Firestore, and manages premium features such as brand inclusion. Provides user feedback and enforces
 * raffle creation limits based on subscription status.
 * 
 * @param {Object} props - Component properties.
 * @param {Object} props.user - The current authenticated user object.
 * 
 * @returns {JSX.Element} The rendered RaffleCreationForm component.
 */
const RaffleCreationForm = ({ user }) => {
  const [raffleDetails, setRaffleDetails] = useState({
    name: '',
    description: '',
    raffleType: '',
    entryDate: '',
    entryTime: '',
    maxEntriesPerAddress: '',
    enableAgeGate: false,
    minimumAge: '',
    restrictedCountries: [],
    includeBrand: false, // New field for the toggle switch
    selectedBrand: '', // New field for the selected brand
  });
  const [errors, setErrors] = useState({});
  const [monthlyRaffleCount, setMonthlyRaffleCount] = useState(0);
  const [monthResetDate, setMonthResetDate] = useState(null);

  // NEW STATE for Premium status
  const [isPremium, setIsPremium] = useState(false);

  // NEW STATE for User's Brands
  const [brands, setBrands] = useState([]);
  const [brandsLoading, setBrandsLoading] = useState(true);
  const [brandsError, setBrandsError] = useState(null);

  const navigate = useNavigate();

  /**
   * Effect hook that runs on component mount and when user or isPremium changes.
   * 
   * - Fetches the current month's raffle count for the user.
   * - Listens to Firestore for changes in the user's subscription status to determine if they are premium.
   * - If the user is premium, fetches their associated brands.
   * 
   * @effect
   * @requires {user, isPremium} - The authenticated user object and their premium status.
   */
  useEffect(() => {
    if (!user) {
      navigate('/');
      return;
    }

    /**
     * Fetches the number of raffles the user has created in the current month.
     * Sets the monthly raffle count and determines the reset date for the raffle limit.
     * 
     * @async
     * @function fetchMonthlyRaffleCount
     * @returns {Promise<void>} A promise that resolves when the raffle count and reset date are set.
     */
    const fetchMonthlyRaffleCount = async () => {
      try {
        const currentMonthStart = new Date();
        currentMonthStart.setDate(1);
        currentMonthStart.setHours(0, 0, 0, 0);

        const q = query(
          collection(firestore, 'raffles'),
          where('ownerId', '==', user.uid),
          where('createdAt', '>=', currentMonthStart)
        );

        const querySnapshot = await getDocs(q);
        setMonthlyRaffleCount(querySnapshot.size);

        if (querySnapshot.size > 0) {
          const createdAtTimestamp = querySnapshot.docs[0].data().createdAt;
          const resetDate = new Date(createdAtTimestamp.toDate());
          resetDate.setMonth(resetDate.getMonth() + 1); // User-specific reset
          setMonthResetDate(resetDate);
        } else {
          const defaultResetDate = new Date();
          defaultResetDate.setDate(1);
          defaultResetDate.setMonth(defaultResetDate.getMonth() + 1);
          defaultResetDate.setHours(0, 0, 0, 0);
          setMonthResetDate(defaultResetDate);
        }
      } catch (error) {
        console.error('Error fetching monthly raffle count:', error);
      }
    };

    /**
     * Sets up a listener for the user's subscription status in Firestore.
     * Updates the `isPremium` state based on the presence of active subscriptions.
     * 
     * @function listenToPremiumStatus
     * @returns {Function} A function to unsubscribe the listener.
     */
    const listenToPremiumStatus = () => {
      const subscriptionsRef = collection(firestore, 'users', user.uid, 'subscriptions');
      const qSubscriptions = query(subscriptionsRef, where('status', '==', 'active'));

      const unsubscribe = onSnapshot(
        qSubscriptions,
        (querySnapshot) => {
          if (!querySnapshot.empty) {
            // If there is at least one active subscription
            setIsPremium(true);
            console.log('User has an active subscription.');
          } else {
            // No active subscriptions
            setIsPremium(false);
            console.log('User does not have an active subscription.');
          }
        },
        (error) => {
          console.error('Error listening to subscription documents:', error);
        }
      );

      // Clean up the listener on unmount
      return unsubscribe;
    };

    /**
     * Fetches the user's associated brands from Firestore if they are a premium user.
     * Updates the `brands` state with the fetched data.
     * 
     * @function fetchUserBrands
     * @returns {Function} A function to unsubscribe the listener.
     */
    const fetchUserBrands = () => {
      if (!isPremium) {
        setBrands([]);
        setBrandsLoading(false);
        return;
      }

      const brandsRef = collection(firestore, 'users', user.uid, 'brands');
      const qBrands = query(brandsRef);

      const unsubscribe = onSnapshot(
        qBrands,
        (querySnapshot) => {
          const brandsData = [];
          querySnapshot.forEach((docSnap) => {
            brandsData.push({ id: docSnap.id, ...docSnap.data() });
          });
          setBrands(brandsData);
          setBrandsLoading(false);
        },
        (error) => {
          console.error('Error fetching brands:', error);
          setBrandsError('Failed to fetch brands.');
          setBrandsLoading(false);
        }
      );

      return unsubscribe;
    };

    fetchMonthlyRaffleCount();
    const unsubscribePremium = listenToPremiumStatus();

    let unsubscribeBrands;
    if (isPremium) {
      unsubscribeBrands = fetchUserBrands();
    }

    // Clean up on unmount
    return () => {
      unsubscribePremium();
      if (unsubscribeBrands) unsubscribeBrands();
    };
  }, [user, navigate, isPremium]);

  /**
   * Handles changes to input fields within the form.
   * Updates the `raffleDetails` state based on user input.
   * 
   * @function handleInputChange
   * @param {Object} e - The event object from the input change.
   */
  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setRaffleDetails((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  /**
   * Handles the toggle switch for including a brand in the raffle.
   * Resets the selected brand when toggled off.
   * 
   * @function handleToggleIncludeBrand
   * @param {Object} e - The event object from the toggle switch.
   */
  const handleToggleIncludeBrand = (e) => {
    const { checked } = e.target;
    setRaffleDetails((prev) => ({
      ...prev,
      includeBrand: checked,
      selectedBrand: '', // Reset selected brand when toggled off
    }));
  };

  /**
   * Handles the selection of a brand from the dropdown menu.
   * Updates the `selectedBrand` state with the chosen brand ID.
   * 
   * @function handleBrandSelect
   * @param {Object} e - The event object from the brand selection.
   */
  const handleBrandSelect = (e) => {
    const { value } = e.target;
    setRaffleDetails((prev) => ({
      ...prev,
      selectedBrand: value,
    }));
  };

  /**
   * Validates the form inputs to ensure all required fields are correctly filled.
   * Updates the `errors` state with any validation errors found.
   * 
   * @function validateForm
   * @returns {boolean} Returns `true` if the form is valid, otherwise `false`.
   */
  const validateForm = () => {
    const {
      name,
      raffleType,
      entryDate,
      entryTime,
      maxEntriesPerAddress,
      enableAgeGate,
      minimumAge,
      includeBrand,
      selectedBrand,
    } = raffleDetails;
    const newErrors = {};

    if (!name.trim()) newErrors.name = 'List name is required.';
    if (!raffleType) newErrors.raffleType = 'Please select a list type.';
    if (!entryDate) newErrors.entryDate = 'Entry deadline date is required.';
    if (!entryTime) newErrors.entryTime = 'Entry deadline time is required.';
    if (!maxEntriesPerAddress || isNaN(maxEntriesPerAddress) || maxEntriesPerAddress <= 0) {
      newErrors.maxEntriesPerAddress = 'Please enter a valid number greater than 0.';
    }
    if (enableAgeGate) {
      if (!minimumAge || isNaN(minimumAge) || minimumAge < 0) {
        newErrors.minimumAge = 'Please enter a valid minimum age.';
      }
    }
    if (includeBrand) {
      if (!selectedBrand) {
        newErrors.selectedBrand = 'Please select a brand to include.';
      }
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  /**
   * Handles the creation of a new raffle.
   * 
   * - Enforces raffle creation limits for non-premium users.
   * - Validates form inputs.
   * - Saves raffle data to Firestore.
   * - Redirects the user to the raffle details page upon successful creation.
   * 
   * @async
   * @function createRaffle
   * @returns {Promise<void>} A promise that resolves when the raffle is successfully created or an error occurs.
   */
  const createRaffle = async () => {
    // 1) If user is NOT Premium => enforce the monthly limit
    if (!isPremium && monthlyRaffleCount >= 5) {
      alert('You have reached the monthly limit of 5 raffles. Upgrade to premium for unlimited raffles.');
      return;
    }

    // 2) Validate form
    if (!validateForm()) {
      return;
    }

    const {
      name,
      description,
      raffleType,
      entryDate,
      entryTime,
      maxEntriesPerAddress,
      enableAgeGate,
      minimumAge,
      restrictedCountries,
      includeBrand,
      selectedBrand,
    } = raffleDetails;

    // Combine date/time into an ISO string
    const entryDeadline = new Date(`${entryDate}T${entryTime}`).toISOString();

    const raffleData = {
      name,
      description,
      raffleType,
      entryDeadline,
      maxEntriesPerAddress: parseInt(maxEntriesPerAddress, 10),
      ownerId: user.uid,
      createdAt: new Date(),
      enableAgeGate,
      minimumAge: enableAgeGate ? parseInt(minimumAge, 10) : null,
      restrictedCountries,
    };

    // Include selected brand if toggle is on
    if (isPremium && includeBrand && selectedBrand) {
      raffleData.selectedBrand = selectedBrand; // Store the brand ID
    }

    try {
      const docRef = await addDoc(collection(firestore, 'raffles'), raffleData);
      navigate(`/List/${docRef.id}`);
    } catch (error) {
      console.error('Error creating raffle:', error);
      alert('Failed to create raffle. Please try again.');
    }
  };

  // Redirect unauthenticated users to the home page
  if (!user) {
    return (
      <Box sx={{ padding: '2rem' }}>
        <Typography variant="body1">Please sign in to create a raffle.</Typography>
      </Box>
    );
  }

  /**
   * Renders the form in a disabled state for non-premium users who have reached the raffle creation limit.
   * Provides an option to upgrade to premium.
   * 
   * @returns {JSX.Element} The rendered disabled form with upgrade prompt.
   */
  if (!isPremium && monthlyRaffleCount >= 5) {
    return (
      <Box sx={{ padding: { xs: '1rem', md: '2rem' } }}>
        <Alert severity="info" sx={{ marginBottom: '1rem' }}>
          You have reached the monthly limit of 5 raffles. Upgrade to premium for unlimited raffles.
          <Tooltip
            title={
              monthResetDate
                ? `Your monthly limit will reset on ${new Date(monthResetDate).toLocaleDateString()} at ${new Date(monthResetDate).toLocaleTimeString()}.`
                : 'Your reset date could not be determined. Please contact support.'
            }
            placement="top"
          >
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
          {/* Replace the existing Button with HandleUpgradeToPremiumButton */}
          <HandleUpgradeToPremiumButton user={user} />
        </Alert>

        <Typography variant="h5" gutterBottom>
          Create a Bitcoin, Ethereum, or Solana address collector to share with your followers
        </Typography>
        <form noValidate autoComplete="off">
          <Grid container spacing={2}>
            {/* The form is disabled for free users over limit */}
            <Grid item xs={12}>
              <TextField
                label="List Name"
                name="name"
                value={raffleDetails.name}
                onChange={handleInputChange}
                required
                fullWidth
                margin="normal"
                disabled
                helperText="Upgrade to premium to enable this field."
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Description"
                name="description"
                value={raffleDetails.description}
                onChange={handleInputChange}
                multiline
                rows={4}
                fullWidth
                margin="normal"
                disabled
                helperText="Upgrade to premium to enable this field."
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal" required disabled>
                <InputLabel id="raffle-type-label">List Type</InputLabel>
                <Select
                  labelId="raffle-type-label"
                  name="raffleType"
                  value={raffleDetails.raffleType}
                  onChange={handleInputChange}
                  label="List Type"
                >
                  <MenuItem value="">
                    <em>--Select--</em>
                  </MenuItem>
                  <MenuItem value="ethereum">Ethereum</MenuItem>
                  <MenuItem value="solana">Solana</MenuItem>
                  <MenuItem value="bitcoin">Bitcoin</MenuItem>
                </Select>
                <FormHelperText>Upgrade to premium to enable this field.</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Max Entries per Address"
                name="maxEntriesPerAddress"
                value={raffleDetails.maxEntriesPerAddress}
                onChange={handleInputChange}
                required
                fullWidth
                margin="normal"
                type="number"
                disabled
                helperText="Upgrade to premium to enable this field."
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Entry Deadline Date"
                type="date"
                name="entryDate"
                value={raffleDetails.entryDate}
                onChange={handleInputChange}
                required
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
                helperText="Upgrade to premium to enable this field."
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Entry Deadline Time"
                type="time"
                name="entryTime"
                value={raffleDetails.entryTime}
                onChange={handleInputChange}
                required
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
                helperText="Upgrade to premium to enable this field."
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="enableAgeGate"
                    checked={raffleDetails.enableAgeGate}
                    onChange={handleInputChange}
                    disabled
                  />
                }
                label="Enable Age Restriction"
              />
              <FormHelperText>Upgrade to premium to enable this feature.</FormHelperText>
            </Grid>

            {raffleDetails.enableAgeGate && (
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Minimum Age"
                  name="minimumAge"
                  value={raffleDetails.minimumAge}
                  onChange={handleInputChange}
                  required
                  fullWidth
                  margin="normal"
                  type="number"
                  disabled
                  helperText="Upgrade to premium to enable this field."
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={createRaffle}
                fullWidth
                sx={{ marginTop: '1rem' }}
                disabled
              >
                Create Wallet Collector
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  }

  // ELSE: user is premium OR user hasn't hit 5.
  return (
    <Box sx={{ padding: { xs: '1rem', md: '2rem' } }}>
      <Typography variant="h5" gutterBottom>
        Create a Bitcoin, Ethereum, or Solana address collector to share with your followers
      </Typography>
      <form noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="List Name"
              name="name"
              value={raffleDetails.name}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
              error={Boolean(errors.name)}
              helperText={errors.name}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Description"
              name="description"
              value={raffleDetails.description}
              onChange={handleInputChange}
              multiline
              rows={4}
              fullWidth
              margin="normal"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              margin="normal"
              required
              error={Boolean(errors.raffleType)}
            >
              <InputLabel id="raffle-type-label">List Type</InputLabel>
              <Select
                labelId="raffle-type-label"
                name="raffleType"
                value={raffleDetails.raffleType}
                onChange={handleInputChange}
                label="List Type"
              >
                <MenuItem value="">
                  <em>--Select--</em>
                </MenuItem>
                <MenuItem value="ethereum">Ethereum</MenuItem>
                <MenuItem value="solana">Solana</MenuItem>
                <MenuItem value="bitcoin">Bitcoin</MenuItem>
              </Select>
              {errors.raffleType && (
                <FormHelperText>{errors.raffleType}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Max Entries per Address"
              name="maxEntriesPerAddress"
              value={raffleDetails.maxEntriesPerAddress}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              error={Boolean(errors.maxEntriesPerAddress)}
              helperText={errors.maxEntriesPerAddress}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Entry Deadline Date"
              type="date"
              name="entryDate"
              value={raffleDetails.entryDate}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(errors.entryDate)}
              helperText={errors.entryDate}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Entry Deadline Time"
              type="time"
              name="entryTime"
              value={raffleDetails.entryTime}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(errors.entryTime)}
              helperText={errors.entryTime}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="enableAgeGate"
                  checked={raffleDetails.enableAgeGate}
                  onChange={handleInputChange}
                />
              }
              label="Enable Age Restriction"
            />
          </Grid>

          {raffleDetails.enableAgeGate && (
            <Grid item xs={12} sm={6}>
              <TextField
                label="Minimum Age"
                name="minimumAge"
                value={raffleDetails.minimumAge}
                onChange={handleInputChange}
                required
                fullWidth
                margin="normal"
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                error={Boolean(errors.minimumAge)}
                helperText={errors.minimumAge}
              />
            </Grid>
          )}

          {/* New Section for Premium Users: Toggle and Dropdown */}
          {isPremium && (
            <>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={raffleDetails.includeBrand}
                        onChange={handleToggleIncludeBrand}
                        name="includeBrand"
                        color="primary"
                      />
                    }
                    label="Include a Brand in Raffle"
                  />
                </FormGroup>
              </Grid>

              {raffleDetails.includeBrand && (
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    margin="normal"
                    required
                    error={Boolean(errors.selectedBrand)}
                  >
                    <InputLabel id="selected-brand-label">Select Brand</InputLabel>
                    <Select
                      labelId="selected-brand-label"
                      name="selectedBrand"
                      value={raffleDetails.selectedBrand}
                      onChange={handleBrandSelect}
                      label="Select Brand"
                    >
                      <MenuItem value="">
                        <em>--Select--</em>
                      </MenuItem>
                      {brandsLoading ? (
                        <MenuItem disabled>
                          <em>Loading brands...</em>
                        </MenuItem>
                      ) : brandsError ? (
                        <MenuItem disabled>
                          <em>Error loading brands</em>
                        </MenuItem>
                      ) : brands.length === 0 ? (
                        <MenuItem disabled>
                          <em>No brands available</em>
                        </MenuItem>
                      ) : (
                        brands.map((brand) => (
                          <MenuItem key={brand.id} value={brand.id}>
                            {brand.name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                    {errors.selectedBrand && (
                      <FormHelperText>{errors.selectedBrand}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              )}
            </>
          )}

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={createRaffle}
              fullWidth
              sx={{ marginTop: '0rem' }}
            >
              Create Wallet Collector
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default RaffleCreationForm;
