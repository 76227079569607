/**
 * @file        /src/components/Navbar.js
 * @version     2.3.0
 * @author      Trevor Bissonette
 * @date        2025-01-24
 * @lastModified 2025-01-24
 * 
 * @summary
 * Implements the navigation bar for the WalletCollector application. Handles user authentication states,
 * displays appropriate navigation links based on user roles and premium status, and ensures responsiveness
 * across different device sizes.
 * 
 * @description
 * The `Navbar` component serves as the primary navigation interface for users interacting with the WalletCollector
 * application. It dynamically adjusts its displayed options based on the user's authentication status and whether
 * the user has an active premium subscription. The component integrates with Firebase for authentication and
 * Firestore for subscription status monitoring. Additionally, it utilizes Material-UI (MUI) components to ensure a
 * consistent and responsive design across various device screen sizes.
 * 
 * Key Features:
 * - **User Authentication Integration:** Utilizes Firebase Authentication to manage user sign-in and sign-out processes.
 * - **Subscription Status Monitoring:** Listens to Firestore documents to determine if a user has an active premium subscription,
 *   enabling or disabling premium-specific navigation options accordingly.
 * - **Responsive Design:** Adapts the navigation layout for both mobile and desktop views using MUI's responsive utilities.
 * - **Dynamic Navigation Items:** Displays different sets of navigation links based on the user's authentication and subscription status.
 * - **Event Handling:** Incorporates event listeners for menu interactions, ensuring a smooth user experience.
 * - **Analytics Integration:** Includes tracking for sign-in events via external analytics services (e.g., X Pixel).
 * 
 * @license
 * © 2025 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 * 
 * @changelog
 * - **2.3.0**: Updated responsive behavior and optimized subscription status listener.
 * - **2.2.0**: Integrated analytics event tracking for sign-in actions.
 * - **2.1.0**: Added conditional rendering for premium users in the navigation menu.
 * - **2.0.0**: Refactored menu item generation for better scalability and maintainability.
 * - **1.0.0**: Initial creation of the Navbar component with basic authentication and routing.
 */

 // src/components/Navbar.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { auth, firestore } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { collection, query, where, onSnapshot } from 'firebase/firestore';

// Import MUI components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

/**
 * @component
 * Navbar
 * 
 * Renders the application's navigation bar, dynamically displaying menu items based on the user's authentication
 * and premium subscription status. Ensures responsiveness for both mobile and desktop views.
 * 
 * @param {Object} props - Component properties.
 * @param {Object} props.user - The current authenticated user object.
 * 
 * @returns {JSX.Element} The rendered Navbar component.
 */
const Navbar = ({ user }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPremium, setIsPremium] = useState(false);

  /**
   * Initiates the Google sign-in process using Firebase Authentication.
   * On successful sign-in, triggers an analytics event.
   * 
   * @async
   * @function handleSignIn
   * @returns {Promise<void>} A promise that resolves when the sign-in process completes.
   */
  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      if (window.twq) {
        window.twq('event', 'tw-ov185-ov186', {
          conversion_id: 'sign-in-success' // Optional: Pass a unique ID if needed
        });
      }
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  /**
   * Signs out the currently authenticated user using Firebase Authentication.
   * 
   * @async
   * @function handleSignOut
   * @returns {Promise<void>} A promise that resolves when the sign-out process completes.
   */
  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error during sign-out:', error);
    }
  };

  /**
   * Opens the mobile navigation menu by setting the anchor element.
   * 
   * @function handleMenuOpen
   * @param {Object} event - The click event triggering the menu open.
   */
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Closes the mobile navigation menu by clearing the anchor element.
   * 
   * @function handleMenuClose
   */
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Utilize MUI's theming and responsive utilities
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  /**
   * Effect hook that monitors the user's subscription status.
   * Listens to the Firestore collection `users/{uid}/subscriptions` for active subscriptions.
   * Updates the `isPremium` state based on the presence of active subscriptions.
   * 
   * @effect
   * @requires {user} - The authenticated user object.
   */
  useEffect(() => {
    if (!user) {
      setIsPremium(false);
      return;
    }

    const subscriptionsRef = collection(firestore, 'users', user.uid, 'subscriptions');
    const qSubscriptions = query(subscriptionsRef, where('status', '==', 'active'));

    const unsubscribe = onSnapshot(
      qSubscriptions,
      (querySnapshot) => {
        const hasActiveSub = !querySnapshot.empty;
        setIsPremium(hasActiveSub);
      },
      (error) => {
        console.error('Error listening to subscription documents:', error);
      }
    );

    return () => unsubscribe();
  }, [user]);

  /**
   * Generates the list of menu items based on the user's authentication and premium status.
   * 
   * @type {Array<Object>}
   */
  const menuItems = user
    ? [
        { text: 'Create List', to: '/create' },
        { text: 'My Lists', to: '/profile' },
        // Conditionally add "My Brands" if the user is premium
        ...(isPremium ? [{ text: 'My Brands', to: '/mybrands' }] : []),
        { text: 'About', to: '/about' },
        { text: 'Contact Us', to: '/contactus' },
        { text: 'Sign Out', action: handleSignOut },
      ]
    : [
        { text: 'Sign In', action: handleSignIn },
        { text: 'About', to: '/about' },
        { text: 'Contact Us', to: '/contactus' },
        { text: 'Terms of Service', to: '/terms' },
        { text: 'Privacy Policy', to: '/policy' },
      ];

  return (
    <AppBar position="static">
      <Toolbar>
        {/* Text and Logo Wrapper */}
        <Box
          component={Link}
          to="/"
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          {/* Logo */}
          <img
            src="/logo513.png" // Replace with your logo's path
            alt="WalletCollector Logo"
            style={{ width: '80px', height: 'auto', marginRight: '0.5rem' }} // Added marginRight for spacing
          />
          {/* App Name */}
          <Typography variant="h6">
            Wallet Collector
          </Typography>
        </Box>

        {/* Menu for Mobile */}
        {isMobile ? (
          <>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleMenuOpen}
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {menuItems.map((item, index) => (
                <MenuItem
                  key={index}
                  component={item.to ? Link : 'div'}
                  to={item.to}
                  onClick={() => {
                    handleMenuClose();
                    if (item.action) {
                      item.action();
                    }
                  }}
                >
                  {item.text}
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          /* Buttons for Desktop */
          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            {menuItems.map((item, index) =>
              item.action ? (
                <Button
                  key={index}
                  color="inherit"
                  onClick={item.action}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {item.text}
                </Button>
              ) : (
                <Button
                  key={index}
                  color="inherit"
                  component={Link}
                  to={item.to}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {item.text}
                </Button>
              )
            )}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
