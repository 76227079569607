/**
 * @file   refundpolicypage.js
 * @brief  Refund Policy legal document page
 * @date   2025-01-10
 * 
 * © 2025 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * LICENSE:
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 */

import React from 'react';

const RefundPolicyPage = () => {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
            <h1 style={{ textAlign: 'center', fontSize: '2rem', marginBottom: '1rem' }}>Refund Policy</h1>
            <p style={{ textAlign: 'center', fontSize: '1rem', color: '#555' }}>Effective Date: January 10, 2025</p>

            {/* 1. Introduction */}
            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>1. Introduction</h2>
                <p>Thank you for choosing Wallet Collector (“we,” “our,” or “us”). We are committed to providing you with high-quality services. This Refund Policy outlines the terms and conditions under which refunds are granted for our premium subscription service priced at $10 per month.</p>
            </section>

            {/* 2. Subscription Fees */}
            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>2. Subscription Fees</h2>
                <p>By subscribing to our premium service, you agree to pay the recurring fee of $10 per month (“Subscription Fee”) through Stripe, our payment processor. Payments are billed on a monthly basis and are non-refundable except as provided in this Refund Policy.</p>
            </section>

            {/* 3. No Refunds for Subscription Fees */}
            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>3. No Refunds for Subscription Fees</h2>
                <p>All subscription fees are <strong>non-refundable</strong>. By subscribing, you acknowledge and agree that the Subscription Fee is charged on a recurring basis and that you will not receive refunds for any fees paid, except as explicitly stated in this Refund Policy.</p>
            </section>

            {/* 4. Eligibility for Refunds */}
            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>4. Eligibility for Refunds</h2>
                <p>Refunds may be granted only under the following limited circumstances:</p>
                <ul>
                    <li><strong>Unauthorized Charges:</strong> If you notice any unauthorized charges on your account, please contact us immediately for investigation and potential refund.</li>
                    <li><strong>Service Unavailability:</strong> If our premium services are unavailable for an extended period due to technical issues beyond our control, you may be eligible for a refund for the affected billing cycle.</li>
                    <li><strong>Legal Requirements:</strong> Refunds may be provided as required by applicable laws and regulations, regardless of other provisions in this Refund Policy.</li>
                </ul>
            </section>

            {/* 5. Non-Refundable Situations */}
            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>5. Non-Refundable Situations</h2>
                <p>Refunds will not be provided in the following cases:</p>
                <ul>
                    <li><strong>Change of Mind:</strong> If you decide to cancel your subscription for any reason other than those specified in the Eligibility for Refunds section.</li>
                    <li><strong>Downgrading Services:</strong> If you choose to downgrade to a free or lower-tier service.</li>
                    <li><strong>Partial Month Usage:</strong> Subscription fees cover the entire billing period; partial usage does not qualify for refunds.</li>
                    <li><strong>Misuse of Services:</strong> Any refunds related to misuse or violation of our Terms of Service will be denied.</li>
                </ul>
            </section>

            {/* 6. How to Request a Refund */}
            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>6. How to Request a Refund</h2>
                <ol>
                    <li><strong>Contact Us:</strong> Send an email to <a href="mailto:walletcollectormain@gmail.com" style={{ color: '#007BFF', textDecoration: 'none' }}>walletcollectormain@gmail.com</a> with the subject line “Refund Request.”</li>
                    <li><strong>Provide Details:</strong> Include your account information, subscription details, reason for the refund request, and any supporting documentation.</li>
                    <li><strong>Review Process:</strong> Our support team will review your request and respond within <strong>5-7 business days</strong>.</li>
                </ol>
            </section>

            {/* 7. Processing Refunds */}
            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>7. Processing Refunds</h2>
                <ul>
                    <li><strong>Approval:</strong> If your refund request meets the criteria outlined in this Refund Policy, the refund will be processed to your original payment method.</li>
                    <li><strong>Timeline:</strong> Approved refunds will be processed within <strong>7-10 business days</strong>.</li>
                    <li><strong>Notification:</strong> You will receive an email notification once the refund has been processed.</li>
                    <li><strong>Stripe Policies:</strong> All refunds are subject to Stripe’s refund policies and processing times.</li>
                </ul>
            </section>

            {/* 8. Cancellation Policy */}
            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>8. Cancellation Policy</h2>
                <p>You may cancel your premium subscription at any time through your <strong>Manage Subscription</strong> page. Cancellation will prevent future billing, but it does not retroactively affect the current billing cycle. Since subscription fees are non-refundable, no refunds will be provided for cancellations made after the billing cycle has commenced, except as outlined in the Eligibility for Refunds section.</p>
            </section>

            {/* 9. Changes to This Refund Policy */}
            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>9. Changes to This Refund Policy</h2>
                <p>We reserve the right to modify this Refund Policy at any time. Any changes will be effective immediately upon posting the updated policy on our website or within the app. Your continued use of the premium service constitutes acceptance of the revised terms.</p>
            </section>

            {/* 10. Contact Information */}
            <section>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>10. Contact Information</h2>
                <p>If you have any questions or concerns about this Refund Policy, please contact us at:</p>
                <p>Email: <a href="mailto:walletcollectormain@gmail.com" style={{ color: '#007BFF', textDecoration: 'none' }}>walletcollectormain@gmail.com</a></p>
            </section>
        </div>
    );
};

export default RefundPolicyPage;
