/**
 * @file        /src/components/SharePermissionsDialog.js
 * @version     2.1.0
 * @author      Trevor Bissonette
 * @date        2024-04-27
 * @lastModified 2025-01-25
 * 
 * @summary
 * Provides a dialog interface for List owners to manage sharing permissions with other users. Allows owners
 * to search for users by username, share List access, and revoke existing permissions.
 * 
 * @description
 * The `SharePermissionsDialog` component enables List owners to grant and revoke access to their Lists for other users.
 * Owners can search for users by their username, share List access, and manage existing shared permissions. The component
 * handles Firestore interactions to fetch shared users, search for users, add new sharing permissions, and revoke existing ones.
 * It ensures a responsive and user-friendly interface using Material-UI components.
 * 
 * Key Features:
 * - **User Search:** Allows owners to search for users by their username to share List access.
 * - **Permission Management:** Enables sharing and revoking List permissions with other users.
 * - **Real-Time Updates:** Fetches and displays the latest shared users when the dialog is opened.
 * - **Error Handling:** Displays error messages for failed operations and success messages upon successful actions.
 * - **Responsive Design:** Utilizes Material-UI components to ensure the dialog is responsive and accessible.
 * 
 * @license
 * © 2025 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 * 
 * @changelog
 * - **2.1.0**: Added inline documentation for improved readability and maintainability. Enhanced batch processing for Firestore queries to support more shared Lists.
 * - **2.0.0**: Refactored component for better performance and scalability. Improved error handling and user feedback mechanisms.
 * - **1.0.0**: Initial creation of the SharePermissionsDialog component with basic sharing and revoking functionalities.
 */

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import { firestore } from '../firebase';
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  serverTimestamp,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import CloseIcon from '@mui/icons-material/Close';

/**
 * SharePermissionsDialog Component
 * 
 * @param {boolean} open - Controls the visibility of the dialog.
 * @param {function} onClose - Function to handle closing the dialog.
 * @param {string} ownerId - UID of the raffle owner.
 * @param {string} raffleId - ID of the raffle to manage permissions for.
 */
const SharePermissionsDialog = ({ open, onClose, ownerId, raffleId }) => {
  // State variables for search input and results
  const [searchUsername, setSearchUsername] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  
  // State variables for shared users and loading status
  const [sharedUsers, setSharedUsers] = useState([]);
  const [loadingSharedUsers, setLoadingSharedUsers] = useState(true);
  
  // State variables for handling sharing status and messages
  const [sharing, setSharing] = useState(false);
  const [error, setError] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  /**
   * Fetches the list of users who have been granted access to the raffle.
   * Executes when the dialog is opened.
   */
  useEffect(() => {
    const fetchSharedUsers = async () => {
      setLoadingSharedUsers(true);
      try {
        const sharedPermissionsRef = collection(firestore, 'sharedPermissions');
        const qShared = query(
          sharedPermissionsRef,
          where('raffleId', '==', raffleId),
          where('ownerId', '==', ownerId)
        );
        const sharedSnap = await getDocs(qShared);
        const users = [];

        // Fetch usernames from 'usernames' collection
        for (const docSnap of sharedSnap.docs) {
          const data = docSnap.data();
          const usernameQuery = query(
            collection(firestore, 'usernames'),
            where('uid', '==', data.sharedWithUid)
          );
          const usernameSnap = await getDocs(usernameQuery);
          if (!usernameSnap.empty) {
            const usernameData = usernameSnap.docs[0].data();
            // Prevent adding the same user multiple times
            if (!users.some(user => user.sharedWithUid === data.sharedWithUid)) {
              users.push({
                permissionId: docSnap.id,
                username: usernameData.username,
                sharedWithUid: data.sharedWithUid,
              });
            }
          }
        }

        setSharedUsers(users);
      } catch (err) {
        console.error('Error fetching shared users:', err);
        setError('Failed to load shared users.');
      } finally {
        setLoadingSharedUsers(false);
      }
    };

    if (open) {
      fetchSharedUsers();
    }
  }, [open, raffleId, ownerId]);

  /**
   * Handles searching for users by username.
   */
  const handleSearch = async () => {
    setError('');
    setSuccessMsg('');
    if (!searchUsername.trim()) {
      setError('Please enter a username to search.');
      return;
    }

    setSearching(true);
    try {
      const usernamesRef = collection(firestore, 'usernames');
      const qUser = query(
        usernamesRef,
        where('username', '==', searchUsername.trim().toLowerCase())
      );
      const querySnapshot = await getDocs(qUser);
      const results = querySnapshot.docs.map(doc => doc.data());
      setSearchResults(results);
      if (results.length === 0) {
        setError('No users found with that username.');
      }
    } catch (err) {
      console.error('Error searching usernames:', err);
      setError('An error occurred while searching. Please try again.');
    } finally {
      setSearching(false);
    }
  };

  /**
   * Handles sharing raffle permissions with a selected user.
   * 
   * @param {string} sharedWithUid - UID of the user to share permissions with.
   */
  const handleShare = async (sharedWithUid) => {
    setError('');
    setSuccessMsg('');
    setSharing(true);
    try {
      // Check if permission already exists
      const sharedPermissionsRef = collection(firestore, 'sharedPermissions');
      const qCheck = query(
        sharedPermissionsRef,
        where('raffleId', '==', raffleId),
        where('ownerId', '==', ownerId),
        where('sharedWithUid', '==', sharedWithUid)
      );
      const existingSnapshot = await getDocs(qCheck);
      if (!existingSnapshot.empty) {
        setError('Permissions already shared with this user.');
        return;
      }

      // Share access to the specific raffle
      await addDoc(sharedPermissionsRef, {
        raffleId: raffleId,
        ownerId: ownerId,
        sharedWithUid: sharedWithUid,
        createdAt: serverTimestamp(),
      });

      setSuccessMsg('Permissions shared successfully!');
      setSearchUsername('');
      setSearchResults([]);

      // Refresh shared users list
      const sharedPermissionsRefRefresh = collection(firestore, 'sharedPermissions');
      const qSharedRefresh = query(
        sharedPermissionsRefRefresh,
        where('raffleId', '==', raffleId),
        where('ownerId', '==', ownerId)
      );
      const sharedSnapRefresh = await getDocs(qSharedRefresh);
      const users = [];
      for (const docSnap of sharedSnapRefresh.docs) {
        const data = docSnap.data();
        const usernameQuery = query(
          collection(firestore, 'usernames'),
          where('uid', '==', data.sharedWithUid)
        );
        const usernameSnap = await getDocs(usernameQuery);
        if (!usernameSnap.empty) {
          const usernameData = usernameSnap.docs[0].data();
          // Prevent adding the same user multiple times
          if (!users.some(user => user.sharedWithUid === data.sharedWithUid)) {
            users.push({
              permissionId: docSnap.id,
              username: usernameData.username,
              sharedWithUid: data.sharedWithUid,
            });
          }
        }
      }
      setSharedUsers(users);
    } catch (err) {
      console.error('Error sharing permissions:', err);
      setError('Failed to share permissions. Please try again.');
    } finally {
      setSharing(false);
    }
  };

  /**
   * Handles revoking raffle permissions from a user.
   * 
   * @param {string} permissionId - ID of the shared permission document to revoke.
   */
  const handleRevoke = async (permissionId) => {
    setError('');
    setSuccessMsg('');
    try {
      const permissionDocRef = doc(firestore, 'sharedPermissions', permissionId);
      await deleteDoc(permissionDocRef);
      setSuccessMsg('Permissions revoked successfully!');

      // Remove the user from the sharedUsers list
      setSharedUsers(prev =>
        prev.filter(user => user.permissionId !== permissionId)
      );
    } catch (err) {
      console.error('Error revoking permissions:', err);
      setError('Failed to revoke permissions. Please try again.');
    }
  };

  /**
   * Handles closing the dialog and resetting relevant states.
   */
  const handleClose = () => {
    // Reset states on close
    setSearchUsername('');
    setSearchResults([]);
    setError('');
    setSuccessMsg('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Manage Shared Permissions
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {/* Search for Users to Share Permissions */}
        <Typography variant="h6" gutterBottom>
          Share List with another user
        </Typography>
        <TextField
          label="Search Username"
          variant="outlined"
          fullWidth
          value={searchUsername}
          onChange={(e) => setSearchUsername(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
          disabled={searching || sharing}
          helperText="Enter the username of the user you want to share this List with."
          sx={{ mb: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          disabled={searching || sharing}
          fullWidth
        >
          {searching ? <CircularProgress size={24} /> : 'Search'}
        </Button>

        {/* Display Errors */}
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}

        {/* Display Success Message */}
        {successMsg && (
          <Typography color="success.main" sx={{ mt: 2 }}>
            {successMsg}
          </Typography>
        )}

        {/* Display Search Results */}
        {searchResults.length > 0 && (
          <List sx={{ mt: 2 }}>
            {searchResults.map((userData, index) => (
              <ListItem
                key={index}
                disabled={sharing}
              >
                <ListItemText primary={`${userData.username}`} />
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => handleShare(userData.uid)}
                  disabled={sharing}
                >
                  {sharing ? <CircularProgress size={16} /> : 'Share'}
                </Button>
              </ListItem>
            ))}
          </List>
        )}

        {/* Divider */}
        <Box sx={{ my: 4 }}>
          <hr />
        </Box>

        {/* Display Shared Users */}
        <Typography variant="h6" gutterBottom>
          Users with Access
        </Typography>
        {loadingSharedUsers ? (
          <CircularProgress />
        ) : sharedUsers.length === 0 ? (
          <Typography variant="body2">No users have access to this List.</Typography>
        ) : (
          <List>
            {sharedUsers.map((sharedUser) => (
              <ListItem key={sharedUser.permissionId}>
                <ListItemText primary={`${sharedUser.username}`} />
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={() => handleRevoke(sharedUser.permissionId)}
                >
                  Revoke
                </Button>
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={sharing}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SharePermissionsDialog;
