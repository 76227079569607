/**
 * @file        /src/components/RaffleEntryForm.js
 * @version     2.1.0
 * @author      Trevor Bissonette
 * @date        2024-04-27
 * @lastModified 2025-01-25
 * 
 * @summary
 * Provides a reusable form for submitting raffle entries within the WalletCollector application. Handles validation,
 * prevents duplicate entries, collects metadata, and securely submits data to Firestore.
 * 
 * @description
 * The `RaffleEntryForm` component enables users to submit their List entries by providing their wallet address and
 * X handle. It validates inputs based on the List type, ensures users do not exceed the maximum allowed entries,
 * and collects additional metadata such as IP address and submission device. Upon successful submission, the entry is
 * securely stored in Firebase Firestore.
 * 
 * Key Features:
 * - **Input Validation:** Validates blockchain addresses and X handles based on the List type.
 * - **Duplicate Prevention:** Ensures users cannot submit more entries than the allowed limit.
 * - **Metadata Collection:** Gathers IP address and device information for each submission.
 * - **Secure Submission:** Utilizes Firestore transactions to safely store entry data.
 * - **User Feedback:** Provides real-time feedback through loading states and success/error messages.
 * - **Responsive Design:** Uses Material-UI components for a consistent and responsive user interface.
 * 
 * @license
 * © 2025 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 * 
 * @changelog
 * - **2.1.0**: Enhanced inline documentation and optimized state management for better scalability.
 * - **2.0.0**: Improved validation logic and added metadata collection features.
 * - **1.0.0**: Initial creation of the RaffleEntryForm component with basic form handling and Firestore integration.
 */

import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase';
import {
  collection,
  serverTimestamp,
  doc,
  getDoc,
  query,
  where,
  getCountFromServer,
  runTransaction,
} from 'firebase/firestore';
import { validateAddress } from '../utils/Validation';
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Alert,
  AlertTitle,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import { detect } from 'detect-browser';
import { Link } from 'react-router-dom';

/**
 * RaffleEntryForm Component
 * 
 * @param {string} raffleId - The unique identifier for the raffle.
 * @param {string} raffleType - The type of blockchain for address validation.
 * @param {object} user - The authenticated user object.
 */
const RaffleEntryForm = ({ raffleId, raffleType, user }) => {
  // State variables for form inputs and submission status
  const [address, setAddress] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [raffleClosed, setRaffleClosed] = useState(false);
  const [maxEntriesPerAddress, setMaxEntriesPerAddress] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [submissionDevice, setSubmissionDevice] = useState(null);
  const [ageConfirmed, setAgeConfirmed] = useState(false);
  const [minimumAge, setMinimumAge] = useState(null);

  // States for managing success notifications
  const [successOpen, setSuccessOpen] = useState(false);
  const [createdDocId, setCreatedDocId] = useState(null);
  const [canAddAnother, setCanAddAnother] = useState(false);

  /**
   * useEffect hook to fetch raffle details and collect metadata on component mount.
   */
  useEffect(() => {
    /**
     * Checks the status of the raffle, including deadlines and entry limits.
     */
    const checkRaffleStatus = async () => {
      try {
        const raffleRef = doc(firestore, 'raffles', raffleId);
        const raffleSnap = await getDoc(raffleRef);

        if (raffleSnap.exists()) {
          const {
            entryDeadline,
            maxEntriesPerAddress: maxEntries,
            enableAgeGate,
            minimumAge: minAge,
          } = raffleSnap.data();

          setMaxEntriesPerAddress(maxEntries);

          if (enableAgeGate) {
            setMinimumAge(minAge);
          }

          if (entryDeadline) {
            const closingDate = new Date(entryDeadline);
            const currentDate = new Date();
            if (closingDate.getTime() < currentDate.getTime()) {
              setRaffleClosed(true);
            }
          }
        }
      } catch (err) {
        console.error('Error checking raffle status:', err);
      }
    };

    checkRaffleStatus();

    /**
     * Detects the user's device information.
     */
    const browser = detect();
    setSubmissionDevice(
      browser ? `${browser.os} - ${browser.name} ${browser.version}` : 'Unknown device'
    );

    /**
     * Fetches the user's IP address using an external API.
     */
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIpAddress(response.data.ip);
      } catch (err) {
        console.error('Error fetching IP address:', err);
      }
    };

    fetchIpAddress();
  }, [raffleId]);

  /**
   * Handles the form submission for a raffle entry.
   * Validates inputs, checks for duplicate entries, and submits data to Firestore.
   * 
   * @param {Event} e - The form submission event.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError(null);

    // Prevent submission if the raffle is closed
    if (raffleClosed) {
      setError('This Form is closed. You cannot submit an entry.');
      setSubmitting(false);
      return;
    }

    // Age confirmation check
    if (minimumAge && !ageConfirmed) {
      setError(`You must confirm that you are at least ${minimumAge} years old.`);
      setSubmitting(false);
      return;
    }

    // Validate the blockchain address
    const trimmedAddress = address.trim();
    if (!validateAddress(trimmedAddress, raffleType)) {
      setError(`Invalid ${raffleType} address.`);
      setSubmitting(false);
      return;
    }

    // Validate the Twitter handle format
    if (!twitterHandle.startsWith('@')) {
      setError('Please enter a valid Twitter handle, starting with @.');
      setSubmitting(false);
      return;
    }

    try {
      // Use a transaction to prevent duplicate entries
      await runTransaction(firestore, async (transaction) => {
        const entriesRef = collection(firestore, 'raffleEntries');
        const q = query(
          entriesRef,
          where('raffleId', '==', raffleId),
          where('address', '==', trimmedAddress)
        );

        // Count existing entries for this address
        const snapshot = await getCountFromServer(q);
        const existingCount = snapshot.data().count;

        if (existingCount >= maxEntriesPerAddress) {
          throw new Error(
            `You have reached the maximum number of entries (${maxEntriesPerAddress}) for this Form.`
          );
        }

        // Create a new entry document
        const newEntryRef = doc(entriesRef);

        // Set entry data within the transaction
        transaction.set(newEntryRef, {
          raffleId,
          address: trimmedAddress,
          twitterHandle: twitterHandle.trim(),
          userId: user ? user.uid : null,
          ipAddress: ipAddress || 'Unavailable',
          submissionDevice: submissionDevice || 'Unknown',
          createdAt: serverTimestamp(),
        });

        // Store the new document ID for user reference
        setCreatedDocId(newEntryRef.id);

        // Determine if the user can add another entry
        const updatedCount = existingCount + 1;
        setCanAddAnother(updatedCount < maxEntriesPerAddress);
      });

      // Reset form fields after successful submission
      setAddress('');
      setTwitterHandle('');
      setAgeConfirmed(false);

      // Display success notification
      setSuccessOpen(true);

    } catch (err) {
      console.error('Error submitting entry:', err);
      setError(err.message || 'Failed to submit entry. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Closes the success notification snackbar.
   */
  const handleCloseSuccess = () => {
    setSuccessOpen(false);
  };

  // Display a message if the raffle is closed
  if (raffleClosed) {
    return (
      <Box sx={{ padding: { xs: '1rem', md: '2rem' } }}>
        <Typography variant="h6" color="error">
          This Entry Form is closed. You cannot submit an entry.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: { xs: '1rem', md: '2rem' } }}>
      {/* Raffle Entry Form */}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ marginTop: '1rem', display: 'flex', alignItems: 'center' }}
            >
              {`Enter your ${raffleType} address`}
              <Tooltip
                title="Using WalletCollect Entry Forms does not require any wallet signatures. It simply verifies that your address is valid on the blockchain. We will never request wallet permissions."
                enterTouchDelay={50}
                leaveTouchDelay={1500}
              >
                <IconButton
                  size="small"
                  aria-label="information about address safety"
                  sx={{ marginLeft: 1 }}
                >
                  <InfoIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder={`Enter your ${raffleType} address`}
              required
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={twitterHandle}
              onChange={(e) => setTwitterHandle(e.target.value)}
              placeholder="Enter your Twitter handle (e.g. @username)"
              required
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>

          {/* Age confirmation checkbox if age gate is enabled */}
          {minimumAge && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ageConfirmed}
                    onChange={(e) => setAgeConfirmed(e.target.checked)}
                    name="ageConfirmed"
                  />
                }
                label={`I confirm that I am at least ${minimumAge} years old.`}
              />
            </Grid>
          )}

          {/* Display error messages if any */}
          {error && (
            <Grid item xs={12}>
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={submitting}
              fullWidth
            >
              {submitting ? 'Submitting...' : 'Submit Entry'}
            </Button>
          </Grid>

          {/* Terms and Privacy Policy links */}
          <Grid item xs={12}>
            <Typography
              variant="caption"
              align="center"
              display="block"
              sx={{ mt: 2 }}
            >
              By using Wallet Collector, you confirm that you have read and agree to the{' '}
              <Link to="/terms" target="_blank" rel="noopener noreferrer">
                Terms
              </Link>{' '}
              and{' '}
              <Link to="/policy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </Link>.
            </Typography>
          </Grid>
        </Grid>
      </form>

      {/* Success Notification Snackbar */}
      <Snackbar
        open={successOpen}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          severity="success"
          sx={{ width: '100%' }}
          onClose={handleCloseSuccess}
          action={
            // Show "Add Another Entry" button if allowed
            canAddAnother ? (
              <Button color="inherit" size="small" onClick={handleCloseSuccess}>
                Add Another Entry
              </Button>
            ) : null
          }
        >
          <AlertTitle>Success!</AlertTitle>
          {createdDocId ? (
            <>
              Your entry has been recorded successfully with REF#
              <strong> {createdDocId}</strong>.
            </>
          ) : (
            'Your entry has been submitted successfully!'
          )}
          <br />
          <br />
          <em>
            For questions or concerns, please email{' '}
            <strong>walletcollectormain@gmail.com</strong> and include your REF#.
             WAGMI
          </em>
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RaffleEntryForm;
