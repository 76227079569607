/**
 * @file   about.js
 * @brief  Simple explanation page for the Wallet Collector app
 * @date   2025-01-24
 * 
 * © 2025 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * LICENSE:
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 */

import React from 'react';

const AboutPage = () => {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
            <h1 style={{ textAlign: 'center', fontSize: '2rem', marginBottom: '1rem' }}>About Wallet Collector</h1>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>What is Wallet Collector?</h2>
                <p>
                    Wallet Collector is a powerful tool designed to simplify the collection and management of Ethereum, Solana, and Bitcoin wallet addresses for raffles, ICOs, and whitelist registrations. Whether you're a creator organizing a raffle/ICO/whitelist or an entrant looking to participate, our app makes the process secure, efficient, and easy to use.
                </p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>How to Use Wallet Collector as a Creator</h2>
                <ul>
                    <li><b>Sign Up or Log In:</b> Create an account or log in to access your dashboard.</li>
                    <li><b>Create a List Compiler:</b> Use the "Create List" form to set up a new list. Provide the necessary details, such as the list name, supported blockchains (Ethereum, Solana, Bitcoin), rules, and deadline.</li>
                    <li><b>Share the Entry Link:</b> Once your list is created, the app generates a unique link. Share this link on your X or other social media platforms to invite entrants.</li>
                    <li><b>Manage Entries:</b> View and manage your lists of wallet addresses submitted by entrants in real-time through your dashboard. Export the list as needed.</li>
                    <li><b>Search Addresses:</b> Use the built-in search feature to quickly find specific wallet addresses. The search results also display the entrant's X handle, helping you easily reach out for communication or verification.</li>
                    <li><b>Open or Closed Status:</b> Easily see which lists are currently accepting entries and which are closed, giving you full control over the availability of your raffles or ICO lists.</li>
                </ul>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>How to Use Wallet Collector as an Entrant</h2>
                <ul>
                    <li><b>Find the List Link:</b> Look for The List link shared by the creator on their X or other platforms.</li>
                    <li><b>Enter Your Wallet Address:</b> Click the link and enter your Ethereum, Solana, or Bitcoin wallet address in the provided form. Double-check your submission for accuracy.</li>
                    <li><b>Social Media Connection:</b> User must provide X handle upon entry for ease of communication between entrant and creator.</li>
                    <li><b>Confirmation:</b> After submitting, you’ll receive a confirmation message that your entry has been recorded. Or an error message if it fails.</li>
                    <li><b>Stay Updated:</b> Follow the creator’s announcements for winner notifications and updates.</li>
                </ul>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>Why Use Wallet Collector?</h2>
                <p>
                    Wallet Collector is designed with security, simplicity, and efficiency in mind. By leveraging blockchain technology and secure data storage, we ensure the authenticity of wallet addresses and protect user data. Our intuitive interface not only organizes and secures the process for entrants but also supports multiple blockchains including Ethereum, Solana, and Bitcoin. This multi-chain support allows creators to manage diverse entries seamlessly and saves them significant time by automating what is traditionally a complex and manual process. This allows creators to focus on running their raffles/ICO's/whitelists while the app handles the tedious tasks of collection, compiling, and organizing.
                </p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>Introducing Premium User Accounts</h2>
                <p>
                    Upgrade to a premium user account to unlock a suite of advanced features designed to enhance your experience and provide greater control over your Wallet Collector activities:
                </p>
                <ul>
                    <li><b>Verified Premium Subscription Badge:</b> Display a verified badge on your created list entry pages, enhancing credibility and trust.</li>
                    <li><b>Unlimited Lists:</b> Create an unlimited number of lists, allowing you to manage multiple raffles, ICOs, or whitelists without restrictions.</li>
                    <li><b>Brand Management:</b> Access the new "My Brands" page to create, edit, and delete brand information. Manage multiple brands seamlessly.</li>
                    <li><b>Customization Options:</b> Enhance your list entry pages by adding images, custom text, and background coloring to match your brand's aesthetic.</li>
                    <li><b>Manage Brands:</b> Efficiently manage all aspects of your brands, including branding assets and related settings.</li>
                    <li><b>Preview Branded Lists:</b> Preview how your lists will look with your brand information, ensuring your entry pages align with your brand's identity before they go live.</li>
                    <li><b>Share Permissions for List Entries:</b> Take control of your data sharing with our advanced permissions system. Premium users can effortlessly share and manage their list compiler data with trusted collaborators or keep it private, ensuring both accessibility and security tailored to your needs.</li>
                </ul>
                <p>
                    These premium features are crafted to provide creators with the tools they need to present their raffles, ICOs, or whitelists professionally and efficiently. By offering enhanced customization and brand management capabilities, Wallet Collector empowers you to create engaging and trustworthy entry pages that resonate with your audience.
                </p>
            </section>

            <section>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>Need Help?</h2>
                <p>If you have any questions or need assistance, contact us at <a href="mailto:walletcollectormain@gmail.com" style={{ color: '#007BFF', textDecoration: 'none' }}>walletcollectormain@gmail.com</a></p>
            </section>
        </div>
    );
};

export default AboutPage;
