/**
 * @file   MyBrands.js
 * @brief  Manages the user's brands within the WalletCollector app, allowing users to create, edit, delete,
 *         and preview their brands. Provides functionalities such as color customization, logo uploads,
 *         and real-time synchronization with Firestore.
 * @date   2025-01-24
 *
 * © 2025 Bissonette Data Solutions Corp. All Rights Reserved.
 *
 * LICENSE:
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 */

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  IconButton,
  Snackbar,
  Alert,
  Tooltip,
  Popover, // Added Popover here
} from '@mui/material';
import { ChromePicker } from 'react-color'; // Imported ChromePicker
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility'; // For Preview
import { firestore, storage } from '../firebase';
import {
  collection,
  query,
  onSnapshot,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const MyBrands = ({ user }) => {
  // State Management
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [brandToDelete, setBrandToDelete] = useState(null);

  // State for brand form data
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    backgroundColor: '#ffffff',
    textColor: '#000000',
    logoFile: null,
    logoUrl: '',
  });

  // NEW: Local preview URL for an immediately selected file
  const [logoPreviewUrl, setLogoPreviewUrl] = useState('');

  const [formLoading, setFormLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // NEW: Brand Preview Dialog State
  const [previewBrand, setPreviewBrand] = useState(null);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  // NEW: State for Color Picker Popovers
  const [anchorElBg, setAnchorElBg] = useState(null);
  const [anchorElText, setAnchorElText] = useState(null);

  // ==============================
  // 1) Fetch Brands from Firestore
  // ==============================
  useEffect(() => {
    if (!user) return;

    const brandsRef = collection(firestore, 'users', user.uid, 'brands');
    const q = query(brandsRef);

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const brandsData = [];
        querySnapshot.forEach((docSnap) => {
          brandsData.push({ id: docSnap.id, ...docSnap.data() });
        });
        setBrands(brandsData);
        setLoading(false);
      },
      (error) => {
        console.error('Error fetching brands:', error);
        setSnackbar({
          open: true,
          message: 'Failed to fetch brands.',
          severity: 'error',
        });
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user]);

  // ===============================
  // 2) Open and Close Form Dialog
  // ===============================
  const handleOpenForm = (brand = null) => {
    if (brand) {
      // Edit Mode
      setSelectedBrand(brand);
      setFormData({
        name: brand.name || '',
        description: brand.description || '',
        backgroundColor: brand.backgroundColor || '#ffffff',
        textColor: brand.textColor || '#000000',
        logoFile: null,
        logoUrl: brand.logoUrl || '',
      });
      setLogoPreviewUrl('');
    } else {
      // Create Mode
      setSelectedBrand(null);
      setFormData({
        name: '',
        description: '',
        backgroundColor: '#ffffff',
        textColor: '#000000',
        logoFile: null,
        logoUrl: '',
      });
      setLogoPreviewUrl('');
    }
    setOpenFormDialog(true);
  };

  const handleCloseForm = () => {
    setOpenFormDialog(false);
    setSelectedBrand(null);
    setFormData({
      name: '',
      description: '',
      backgroundColor: '#ffffff',
      textColor: '#000000',
      logoFile: null,
      logoUrl: '',
    });
    setLogoPreviewUrl('');
    // Close any open color pickers
    setAnchorElBg(null);
    setAnchorElText(null);
  };

  // ========================
  // 3) Input & Color Changes
  // ========================
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLogoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFormData({ ...formData, logoFile: file });

      // Generate a local preview
      const previewUrl = URL.createObjectURL(file);
      setLogoPreviewUrl(previewUrl);
    }
  };

  // Handlers for opening color pickers
  const handleOpenColorPickerBg = (event) => {
    setAnchorElBg(event.currentTarget);
  };

  const handleOpenColorPickerText = (event) => {
    setAnchorElText(event.currentTarget);
  };

  // Handlers for closing color pickers
  const handleCloseColorPickerBg = () => {
    setAnchorElBg(null);
  };

  const handleCloseColorPickerText = () => {
    setAnchorElText(null);
  };

  // Handlers for color changes
  const handleBackgroundColorChange = (color) => {
    setFormData({ ...formData, backgroundColor: color.hex });
  };

  const handleTextColorChange = (color) => {
    setFormData({ ...formData, textColor: color.hex });
  };

  // ===========================
  // 4) Create / Update a Brand
  // ===========================
  const handleFormSubmit = async () => {
    const { name, description, backgroundColor, textColor, logoFile } = formData;

    if (!name.trim()) {
      setSnackbar({
        open: true,
        message: 'Brand name is required.',
        severity: 'warning',
      });
      return;
    }

    setFormLoading(true);

    try {
      let logoUrl = formData.logoUrl;

      // If a new logo file is selected, upload it
      if (logoFile) {
        const logoRef = ref(
          storage,
          `users/${user.uid}/brands/${Date.now()}_${logoFile.name}`
        );
        const snapshot = await uploadBytes(logoRef, logoFile);
        logoUrl = await getDownloadURL(snapshot.ref);
      }

      if (selectedBrand) {
        // Update existing brand
        const brandDocRef = doc(
          firestore,
          'users',
          user.uid,
          'brands',
          selectedBrand.id
        );
        await updateDoc(brandDocRef, {
          name,
          description,
          backgroundColor,
          textColor,
          logoUrl,
          updatedAt: serverTimestamp(),
        });
        setSnackbar({
          open: true,
          message: 'Brand updated successfully.',
          severity: 'success',
        });
      } else {
        // Create new brand
        const brandsRef = collection(firestore, 'users', user.uid, 'brands');
        await addDoc(brandsRef, {
          name,
          description,
          backgroundColor,
          textColor,
          logoUrl,
          createdAt: serverTimestamp(),
        });
        setSnackbar({
          open: true,
          message: 'Brand created successfully.',
          severity: 'success',
        });
      }

      handleCloseForm();
    } catch (error) {
      console.error('Error saving brand:', error);
      setSnackbar({
        open: true,
        message: 'Failed to save brand. Please try again.',
        severity: 'error',
      });
    } finally {
      setFormLoading(false);
    }
  };

  // ===========================
  // 5) Confirm Brand Deletion
  // ===========================
  const handleOpenDeleteDialog = (brand) => {
    setBrandToDelete(brand);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setBrandToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (!brandToDelete) return;
    try {
      const brandDocRef = doc(
        firestore,
        'users',
        user.uid,
        'brands',
        brandToDelete.id
      );
      await deleteDoc(brandDocRef);
      setSnackbar({
        open: true,
        message: 'Brand deleted successfully.',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error deleting brand:', error);
      setSnackbar({
        open: true,
        message: 'Failed to delete brand. Please try again.',
        severity: 'error',
      });
    } finally {
      handleCloseDeleteDialog();
    }
  };

  // ==========================
  // 6) Preview Entry Page
  // ==========================
  const handleOpenPreview = (brand) => {
    setPreviewBrand(brand);
    setOpenPreviewDialog(true);
  };

  const handleClosePreview = () => {
    setPreviewBrand(null);
    setOpenPreviewDialog(false);
  };

  // ==========================
  // 7) Close Snackbar
  // ==========================
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ padding: { xs: '1rem', sm: '2rem' }, maxWidth: '1200px', margin: '0 auto' }}>
      {/* Header Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: { xs: 'flex-start', sm: 'center' },
          mb: 4,
        }}
      >
        <Typography variant="h4" gutterBottom>
          My Brands
        </Typography>
        <Button variant="contained" color="primary" onClick={() => handleOpenForm()}>
          Create New Brand
        </Button>
      </Box>

      {/* Loading Indicator */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 10 }}>
          <CircularProgress />
        </Box>
      ) : brands.length === 0 ? (
        <Typography variant="body1">
          You have no brands. Click "Create New Brand" to add one.
        </Typography>
      ) : (
        <Grid container spacing={4}>
          {brands.map((brand) => (
            <Grid item key={brand.id} xs={12} sm={6} md={4}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                {brand.logoUrl && (
                  <CardMedia
                    component="img"
                    height="140"
                    image={brand.logoUrl}
                    alt={`${brand.name} Logo`}
                  />
                )}
                <CardContent
                  sx={{
                    flexGrow: 1,
                    backgroundColor: brand.backgroundColor,
                    color: brand.textColor,
                  }}
                >
                  <Typography gutterBottom variant="h5" component="div">
                    {brand.name}
                  </Typography>
                  <Typography variant="body2" color="inherit">
                    {brand.description || 'No description provided.'}
                  </Typography>
                </CardContent>
                <CardActions>
                  {/* Edit Brand */}
                  <Tooltip title="Edit Brand" arrow>
                    <IconButton
                      color="primary"
                      onClick={() => handleOpenForm(brand)}
                      aria-label="edit brand"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>

                  {/* Delete Brand */}
                  <Tooltip title="Delete Brand" arrow>
                    <IconButton
                      color="error"
                      onClick={() => handleOpenDeleteDialog(brand)}
                      aria-label="delete brand"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>

                  {/* Preview Brand */}
                  <Tooltip title="Preview Entry Page" arrow>
                    <IconButton
                      color="secondary"
                      onClick={() => handleOpenPreview(brand)}
                      aria-label="preview brand"
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Create/Edit Brand Dialog */}
      <Dialog open={openFormDialog} onClose={handleCloseForm} fullWidth maxWidth="sm">
        <DialogTitle>{selectedBrand ? 'Edit Brand' : 'Create New Brand'}</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mt: 1,
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              required
              label="Brand Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              multiline
              rows={3}
              fullWidth
            />
            {/* Enhanced Color Pickers */}
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Background Color:
              </Typography>
              <Box
                onClick={handleOpenColorPickerBg}
                sx={{
                  width: '100%',
                  height: '40px',
                  backgroundColor: formData.backgroundColor,
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '10px',
                }}
              >
                <Typography variant="body2">{formData.backgroundColor}</Typography>
              </Box>
              <Popover
                open={Boolean(anchorElBg)}
                anchorEl={anchorElBg}
                onClose={handleCloseColorPickerBg}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <ChromePicker
                  color={formData.backgroundColor}
                  onChangeComplete={handleBackgroundColorChange}
                  disableAlpha
                />
              </Popover>
            </Box>

            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Text Color:
              </Typography>
              <Box
                onClick={handleOpenColorPickerText}
                sx={{
                  width: '100%',
                  height: '40px',
                  backgroundColor: formData.textColor,
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '10px',
                }}
              >
                <Typography variant="body2">{formData.textColor}</Typography>
              </Box>
              <Popover
                open={Boolean(anchorElText)}
                anchorEl={anchorElText}
                onClose={handleCloseColorPickerText}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <ChromePicker
                  color={formData.textColor}
                  onChangeComplete={handleTextColorChange}
                  disableAlpha
                />
              </Popover>
            </Box>

            {/* Logo Upload */}
            <Button
              variant="contained"
              component="label"
              startIcon={<PhotoCamera />}
              sx={{ alignSelf: 'flex-start', mt: 2 }}
            >
              {selectedBrand ? 'Change Logo' : 'Upload Logo'}
              <input type="file" hidden accept="image/*" onChange={handleLogoChange} />
            </Button>
            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
              {selectedBrand
                ? 'Select a new logo to replace the existing one.'
                : 'Upload a logo that represents your brand. Recommended size: 966x640 pixels.'}
            </Typography>

            {/* Local Preview of Newly Selected Logo */}
            {logoPreviewUrl && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1">Selected Logo Preview:</Typography>
                <Box
                  component="img"
                  src={logoPreviewUrl}
                  alt="Local Logo Preview"
                  sx={{ width: '100%', maxHeight: '200px', objectFit: 'contain', mt: 1 }}
                />
              </Box>
            )}

            {/* If editing an existing brand but haven't selected a new file, show the old logo */}
            {!logoPreviewUrl && formData.logoUrl && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1">Current Logo:</Typography>
                <Box
                  component="img"
                  src={formData.logoUrl}
                  alt="Existing Brand Logo"
                  sx={{ width: '100%', maxHeight: '200px', objectFit: 'contain', mt: 1 }}
                />
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm} disabled={formLoading}>
            Cancel
          </Button>
          <Button
            onClick={handleFormSubmit}
            variant="contained"
            color="primary"
            disabled={formLoading}
          >
            {formLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : selectedBrand ? (
              'Update'
            ) : (
              'Create'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-confirmation-dialog"
      >
        <DialogTitle id="delete-confirmation-dialog">Delete Brand</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the brand "{brandToDelete?.name}"? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Feedback */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Preview Brand Dialog - Mimicking RafflePage styling without the Verified Icon */}
      <Dialog open={openPreviewDialog} onClose={handleClosePreview} fullWidth maxWidth="md">
        <DialogTitle>Preview Entry Page</DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          {previewBrand && (
            <Box
              sx={{
                backgroundColor: previewBrand.backgroundColor || '#ffffff',
                color: previewBrand.textColor || '#000000',
                minHeight: '600px', // Increased height for more content
                padding: '2rem',
              }}
            >
              {/* Raffle Title */}
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontSize: { xs: '2rem', md: '2.5rem' },
                  textAlign: { xs: 'center', md: 'left' },
                  mb: 2,
                }}
              >
                Sample List Name
              </Typography>

              {/* Raffle Description */}
              <Typography
                variant="body1"
                gutterBottom
                sx={{ textAlign: { xs: 'center', md: 'left' }, mb: 3 }}
              >
                This is a sample description for the list entry page. It provides details about the list, what participants can win,
                and any other relevant information to entice users to join.
              </Typography>

              {/* Raffle Details */}
              <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" gutterBottom>
                    <strong>Type:</strong> Ethereum
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Entry Deadline:</strong> January 10, 2030, 04:20:00 AM
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" gutterBottom>
                    <strong>Max Entries per Address:</strong> 1
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Total Number of Entrants:</strong> 10,000
                  </Typography>
                </Grid>
              </Grid>

              {/* Logo if available */}
              {previewBrand.logoUrl && (
                <Box sx={{ textAlign: 'center', mb: 3 }}>
                  <img
                    src={previewBrand.logoUrl}
                    alt={`${previewBrand.name} Logo`}
                    style={{ maxHeight: '100px', borderRadius: '8px' }}
                  />
                </Box>
              )}

              {/* Mock entry form container */}
              <Box
                sx={{
                  backgroundColor: '#ffffff',
                  color: '#000000',
                  maxWidth: '500px',
                  margin: '0 auto',
                  padding: '1.5rem',
                  borderRadius: '8px',
                  boxShadow: 3,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Enter Your Address
                </Typography>
                <TextField
                  label="Your Address"
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 2 }}
                  disabled
                  placeholder="e.g., 0x1234...abcd"
                />
                <TextField
                  label="Twitter Handle"
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 2 }}
                  disabled
                  placeholder="@username"
                />
                <Button variant="contained" color="primary" fullWidth disabled>
                  Submit
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePreview}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MyBrands;
