/**
 * @file        /firebase.js
 * @version     2.1.0
 * @author      Trevor Bissonette
 * @date        2024-04-27
 * @lastModified 2025-01-24
 * 
 * @summary
 * Initializes and configures Firebase services for the WalletCollector application.
 * Provides authentication, Firestore database, and storage functionalities by
 * connecting to Firebase using environment variables for secure configuration.
 * 
 * @description
 * The `firebase.js` module serves as the central hub for integrating Firebase services
 * into the WalletCollector application. It initializes the Firebase app using the
 * provided configuration and exports essential Firebase services such as Authentication,
 * Firestore, and Storage for use throughout the application.
 * 
 * Key Features:
 * - **Firebase Initialization:** Configures Firebase using environment variables to ensure
 *   sensitive information like API keys and project IDs are securely managed.
 * - **Authentication Service:** Sets up Firebase Authentication to handle user sign-in,
 *   sign-up, and authentication state management.
 * - **Firestore Database:** Initializes Firestore for real-time database functionalities,
 *   enabling the application to store and retrieve user data, Lists, and other entities.
 * - **Storage Service:** Configures Firebase Storage to manage file uploads and downloads,
 *   such as user profile images and List assets.
 * - **Environment Variable Integration:** Utilizes environment variables to keep configuration
 *   details secure and adaptable across different deployment environments (development,
 *   staging, production).
 * 
 * @license
 * © 2025 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 * 
 * @changelog
 * - **1.0.0**: Initial creation of the Firebase setup module, including Authentication and Firestore services.
 */

// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

/**
 * Firebase configuration object using environment variables.
 * Ensures that sensitive information is kept secure and not exposed in the codebase.
 */
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

/**
 * Initializes the Firebase application with the provided configuration.
 * This setup is essential for connecting the app to Firebase services.
 */
const app = initializeApp(firebaseConfig);

/**
 * Initializes and exports Firebase Authentication service.
 * This service manages user authentication, including sign-in and sign-out processes.
 * 
 * @type {import('firebase/auth').Auth}
 */
export const auth = getAuth(app);

/**
 * Initializes and exports Firestore service.
 * Firestore is a scalable and flexible NoSQL cloud database to store and sync data for client- and server-side development.
 * 
 * @type {import('firebase/firestore').Firestore}
 */
export const firestore = getFirestore(app);

/**
 * Initializes and exports Firebase Storage service.
 * Firebase Storage provides secure file uploads and downloads for your Firebase apps, regardless of network quality.
 * 
 * @type {import('firebase/storage').FirebaseStorage}
 */
export const storage = getStorage(app);
