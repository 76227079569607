/**
 * @file        /components/Footer.js
 * @version     1.2.0
 * @author      Trevor Bissonette
 * @date        2024-04-27
 * @lastModified 2025-01-24
 * 
 * @summary
 * Renders the footer component for the WalletCollector application. 
 * The footer includes social media links and navigational links to important pages 
 * such as About, Terms of Service, Privacy Policy, and Contact Us.
 * 
 * @description
 * The `Footer` component serves as the bottom section of the WalletCollector application, 
 * providing users with quick access to social media profiles and essential informational pages. 
 * It utilizes Material-UI components for styling and layout, ensuring a responsive and 
 * visually consistent footer across all pages.
 * 
 * Key Features:
 * - **Social Media Integration:** Includes icon buttons that link to the application's 
 *   social media profiles (e.g., X) for enhanced user engagement and connectivity.
 * - **Navigational Links:** Provides easy access to important informational pages such as 
 *   About, Terms of Service, Privacy Policy, and Contact Us, improving user experience and 
 *   accessibility.
 * - **Responsive Design:** Utilizes Material-UI's Box and Typography components to ensure 
 *   the footer is responsive and maintains a consistent appearance across different devices 
 *   and screen sizes.
 * - **Styling and Theming:** Adheres to the application's theme by using primary colors and 
 *   maintaining a cohesive color scheme, enhancing the overall aesthetic of the application.
 * 
 * @license
 * © 2025 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 * 
 * @changelog
 * - **1.2.0**: Updated social media link to point to the correct X handle. Enhanced accessibility 
 *   by adding `aria-label` to icon buttons.
 * - **1.1.0**: Improved styling for better responsiveness and added additional navigational links.
 * - **1.0.0**: Initial creation of the Footer component with basic social media and navigational links.
 */

 // src/components/Footer.js

import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TwitterIcon from '@mui/icons-material/Twitter';

/**
 * @component
 * Footer
 * 
 * Renders the footer section of the WalletCollector application. 
 * Includes social media links and navigational links to essential pages.
 * 
 * @returns {JSX.Element} The rendered Footer component.
 */
const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'primary.main',
        color: 'white',
        py: 3,
        mt: 'auto',
        textAlign: 'center',
        width: '100%',
        position: 'relative',
        bottom: 0,
      }}
    >
      {/* Copyright */}
      <Typography variant="body2" sx={{ mb: 2 }}>
        &copy; {new Date().getFullYear()} Bissonette Data Solutions Corp. All rights reserved.
      </Typography>

      {/* Social Media Links */}
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>  
        <IconButton
          href="https://www.twitter.com/0xWalletCollect"
          target="_blank"
          rel="noopener noreferrer"
          color="inherit"
          aria-label="Twitter"
        >
          <TwitterIcon />
        </IconButton>
        {/* Additional social media icons can be added here */}
      </Box>

      {/* Navigational Links */}
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3 }}>
        <Link to="/about" style={{ color: 'inherit', textDecoration: 'none' }}>
          About
        </Link>
        <Link to="/terms" style={{ color: 'inherit', textDecoration: 'none' }}>
          Terms of Service
        </Link>
        <Link to="/policy" style={{ color: 'inherit', textDecoration: 'none' }}>
          Privacy Policy
        </Link>
        <Link to="/contactus" style={{ color: 'inherit', textDecoration: 'none' }}>
          Contact Us
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
