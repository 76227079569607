/**
 * @file        RaffleEntryPage.js
 * @version     2.0.0
 * @author      Trevor Bissonette
 * @date        2024-04-27
 * @lastModified 2025-01-24
 * 
 * @summary
 * Renders the dedicated page for entering a List within the WalletCollector app. This page allows users
 * to submit their Bitcoin, Ethereum or Solana addresses, provide their X handles for creator-entry communication, and confirm their
 * age if required. For List owners, it displays existing entries, enabling them to monitor participation.
 * 
 * @description
 * The `RaffleEntryPage` component is a React functional component that facilitates user interaction with
 * Lists. It handles address validation, submission to Firestore, and retrieval of existing entries for List
 * owners. The component manages multiple states to track user inputs, loading statuses, and error handling.
 * It leverages Firebase Firestore for data operations and Material-UI (MUI) for styling and UI components.
 * 
 * Key Features:
 * - **Address Validation:** Ensures that submitted Ethereum and Solana addresses are valid.
 * - **Age Confirmation:** If enabled, requires users to confirm they meet the minimum age requirement.
 * - **Submission Handling:** Manages the process of submitting List entries to Firestore.
 * - **Entry Display:** For List owners, fetches and displays all existing entries.
 * - **Real-Time Updates:** Updates the total number of entrants dynamically upon new submissions.
 * 
 * @license
 * © 2025 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 * 
 * @changelog
 * - **2.0.0**: Enhanced documentation and added comprehensive JSDoc annotations.
 * - **1.0.0**: Initial creation of the List Entry Page component.
 */

 // src/pages/RaffleEntryPage.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { firestore } from '../firebase';
import {
  doc,
  getDoc,
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  getCountFromServer,
  getDocs,
} from 'firebase/firestore';
import { validateAddress } from '../utils/Validation';
import {
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

/**
 * @component
 * RaffleEntryPage
 * 
 * The main component for the raffle entry page. It allows users to submit their wallet addresses
 * and Twitter handles to enter a raffle. If the user is the raffle owner, it displays all existing entries.
 * 
 * @returns {JSX.Element} The rendered Raffle Entry Page.
 */
const RaffleEntryPage = ({ user }) => {
  const { raffleId } = useParams();
  const [raffle, setRaffle] = useState(null);
  const [address, setAddress] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const [ageConfirmed, setAgeConfirmed] = useState(false);
  const [ageError, setAgeError] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [totalEntries, setTotalEntries] = useState(null);
  const [entries, setEntries] = useState([]);

  // ----------------------------------
  // Effects: Load Raffle Data
  // ----------------------------------
  useEffect(() => {
    /**
     * Fetches raffle data and existing entries from Firestore.
     */
    const fetchRaffleData = async () => {
      try {
        const raffleRef = doc(firestore, 'raffles', raffleId);
        const raffleSnap = await getDoc(raffleRef);
        if (raffleSnap.exists()) {
          const raffleData = raffleSnap.data();
          setRaffle(raffleData);

          // Fetch total number of entries using getCountFromServer
          const entriesRef = collection(firestore, 'raffleEntries');
          const q = query(entriesRef, where('raffleId', '==', raffleId));
          const countSnap = await getCountFromServer(q);
          setTotalEntries(countSnap.data().count);

          // If the user is the raffle owner, fetch all entries
          if (user && user.uid === raffleData.ownerId) {
            const entriesSnap = await getDocs(q);
            const entriesList = entriesSnap.docs.map((doc) => doc.data());
            setEntries(entriesList);
          }
        } else {
          setError('List not found.');
        }
      } catch (err) {
        console.error('Error fetching list:', err);
        setError('Failed to load list.');
      } finally {
        setLoading(false);
      }
    };

    fetchRaffleData();
  }, [raffleId, user]);

  // ----------------------------------
  // Handlers
  // ----------------------------------

  /**
   * Handles the submission of a raffle entry. Validates inputs and adds the entry to Firestore.
   * @param {Event} e - The form submission event.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);
    setAgeError('');

    // Age confirmation handling
    if (raffle?.enableAgeGate && !ageConfirmed) {
      setAgeError(`You must confirm that you are at least ${raffle.minimumAge} years old.`);
      return;
    }

    // Address validation
    if (!validateAddress(address.trim(), raffle?.raffleType)) {
      setError(`Invalid ${raffle?.raffleType || 'unknown'} address.`);
      return;
    }

    // Twitter handle validation
    if (!twitterHandle.trim().startsWith('@')) {
      setError('Please enter a valid Twitter handle starting with @.');
      return;
    }

    try {
      const entriesRef = collection(firestore, 'raffleEntries');

      // Check for duplicate entries based on address
      const q = query(
        entriesRef,
        where('raffleId', '==', raffleId),
        where('address', '==', address.trim())
      );
      const existingEntriesSnap = await getDocs(q);
      const entryCount = existingEntriesSnap.size;

      if (entryCount >= raffle.maxEntriesPerAddress) {
        setError('You have reached the maximum number of entries allowed for this address.');
        return;
      }

      // Add new entry to Firestore
      await addDoc(entriesRef, {
        raffleId,
        address: address.trim(),
        twitterHandle: twitterHandle.trim(),
        userId: user ? user.uid : null,
        createdAt: serverTimestamp(),
      });
      setSuccess(true);
      setAddress('');
      setTwitterHandle('');
      setAgeConfirmed(false);
      // Update total entries count
      setTotalEntries((prevCount) => (prevCount !== null ? prevCount + 1 : null));
    } catch (err) {
      console.error('Error submitting entry:', err);
      setError('Failed to submit your entry.');
    }
  };

  // ----------------------------------
  // Render Logic
  // ----------------------------------

  // Display loading state
  if (loading)
    return (
      <Box sx={{ textAlign: 'center', padding: '2rem' }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ marginTop: '1rem' }}>
          Loading List...
        </Typography>
      </Box>
    );

  // Display error state
  if (error)
    return (
      <Box sx={{ padding: '2rem' }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );

  // Display success state
  if (success)
    return (
      <Box sx={{ padding: '2rem' }}>
        <Alert severity="success">Thank you for entering the list!</Alert>
      </Box>
    );

  return (
    <Box sx={{ padding: { xs: '1rem', md: '2rem' } }}>
      <Grid container spacing={2}>
        {/* Raffle Title */}
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontSize: { xs: '2rem', md: '2.5rem' } }}
          >
            {raffle?.name || 'List'}
          </Typography>
        </Grid>

        {/* Raffle Description */}
        <Grid item xs={12}>
          <Typography variant="body1">
            {raffle?.description || 'No description provided.'}
          </Typography>
        </Grid>

        {/* Entry Form */}
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Typography variant="h6" gutterBottom sx={{ marginTop: '1rem' }}>
              Enter your {raffle?.raffleType} address
            </Typography>
            <TextField
              id="address"
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder={`Enter your ${raffle?.raffleType} address`}
              required
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              id="twitterHandle"
              type="text"
              value={twitterHandle}
              onChange={(e) => setTwitterHandle(e.target.value)}
              placeholder="Enter your Twitter handle (e.g. @username)"
              required
              fullWidth
              margin="normal"
              variant="outlined"
            />

            {/* Age Confirmation */}
            {raffle?.enableAgeGate && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ageConfirmed}
                    onChange={(e) => setAgeConfirmed(e.target.checked)}
                    name="ageConfirmed"
                  />
                }
                label={`I confirm that I am at least ${raffle.minimumAge} years old.`}
              />
            )}
            {ageError && (
              <Typography variant="body2" color="error" sx={{ marginTop: '0.5rem' }}>
                {ageError}
              </Typography>
            )}

            {/* Display Error Message */}
            {error && (
              <Typography variant="body2" color="error" sx={{ marginTop: '0.5rem' }}>
                {error}
              </Typography>
            )}

            {/* Submit Button */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ marginTop: '1rem' }}
              fullWidth
            >
              Submit
            </Button>
          </form>
        </Grid>

        {/* Total Entries Count */}
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ marginTop: '1rem' }}>
            Total number of entrants: {totalEntries !== null ? totalEntries : 'Loading...'}
          </Typography>
        </Grid>

        {/* Display Entries for Raffle Owners */}
        {user && user.uid === raffle?.ownerId && (
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ marginTop: '2rem' }}>
              Entries:
            </Typography>
            <List>
              {entries.map((entry, index) => (
                <ListItem key={index} divider>
                  <ListItemText primary={`${entry.address} - ${entry.twitterHandle}`} />
                </ListItem>
              ))}
            </List>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default RaffleEntryPage;
